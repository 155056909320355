import { notifyError } from "./notifications";

const { createAxios } = require("./axiosWrapper");

// TODO: Replace Temp with actual controller!!
const bookingOpeningUrl = `${process.env.REACT_APP_GSS_ROOM_BOOKINGS_API_URL}/api/TempBookingOpening`;

const axios = () => createAxios(bookingOpeningUrl);

export const getBookingOpeningDatesAsync = async (
  customerGroupId, windowId
) => {
  try {
    const requestUrl =
      `get-booking-opening-dates-for-customer-group-and-window?customerGroupId=${customerGroupId}&windowId=${windowId}`;

    const bookingOpeningDates = await axios().get(requestUrl);

    return bookingOpeningDates.data ? bookingOpeningDates.data : [];
  } catch (err) {
    notifyError("Failed to get the booking opening.", err);
  }
};


