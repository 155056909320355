import "./RoomBookingsCommonStyles.scss"
import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import {
  Grid,
  Segment,
  Button,
  Form,
  Radio,
  Message,
  Label,
  Modal,
  Header,
} from "semantic-ui-react";
import * as Unicons from "@iconscout/react-unicons";
// import { getCurrentSalesPeriod } from "../../lib/apiSalesPeriod";
// import { getAllUniqueYears } from "../dashboard/Dashboard.functions";
import { CustomerSelectionSlider } from "../../common-mobile/CustomerSelectionSlider/CustomerSelectionSlider";
import { SelectedCustomerGroupContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import BookingDayCard from "./Cards/BookingDayCard";
import BookingTimeSlotCard from "./Cards/BookingTimeSlotCard";
import { getLocationsAsync, getLocationAsync } from "../../lib/apiLocations";
import { getBookingOpeningDatesAsync } from "../../lib/apiBookingOpenings";
import { getBookingAsync, updateBooking } from "../../lib/apiBookings";
import BookingItems from "./BookingItems";
import { useHistory, Prompt } from "react-router-dom";
import moment from "moment";
import { getCustomerGroup } from "../../lib/apiOrderManagement";
import NumberFormat from "react-number-format";
import { Popup } from "../../common/Popup/Popup";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import {
  ConfirmationModal,
  confirmationChoices,
} from "../../common/ConfirmationModal/ConfirmationModal";
import roomsMap from "../../media/gss-room-bookings/revB_DBG-booking-room-map.pdf";

/*
TODO: Clean up file:
- ensure you cannot book a date in the past AND CERTAIN HOURS BEFORE BOOKING START
- add signal r and cater for room availability 
- move shared methods to a file with Create container
- remove all in-line styling
- optimise some functions

*/

const getLocations = async (setLocations, locations, setLoading) => {
  try {
    if (locations == null || locations?.length === 0) {
      setLoading(true);
      const locationResponse = await getLocationsAsync();
      if (locationResponse && locationResponse.length > 0) {
        setLocations(locationResponse);
      }
    }
  } finally {
    setLoading(false);
  }
};

const getLocation = async (setLocation, location, setLoading) => {
  try {
    if (location) {
      setLoading(true);
      const locationResponse = await getLocationAsync(location);
      if (locationResponse) {
        setLocation(locationResponse);
      }
    }
  } finally {
    setLoading(false);
  }
};

const getBooking = async (
  setBooking,
  bookingId,
  setLoading,
  setBookingWindow
) => {
  try {
    setLoading(true);
    const bookingResponse = await getBookingAsync(bookingId);
    if (bookingResponse) {
      setBooking(bookingResponse);
      setBookingWindow(bookingResponse.bookingWindow);
    }
  } finally {
    setLoading(false);
  }
};

const getBookingOpeningDates = async (
  selectedCustomerGroup,
  setLoading,
  setBookingOpeningDates,
  bookingWindowId
) => {
  setLoading(true);

  if (selectedCustomerGroup && bookingWindowId) {
    const data = await getBookingOpeningDatesAsync(
      selectedCustomerGroup,
      bookingWindowId
    );

    await setBookingOpeningDates(data);
  }

  setLoading(false);
};

export const fetchCustomerGroup = async (setCustomerGroup, customerGroupId) => {
  if (customerGroupId) {
    const customerGroup = await getCustomerGroup(customerGroupId);
    setCustomerGroup(customerGroup);

    return customerGroup;
  }
};

export const RoomBookingsEditForm = ({ values, handleSubmit }) => {
  // const [currentSalesPeriod, setCurrentSalesPeriod] = useState(null);
  const [selectedITOYear, setSelectedITOYear] = useState(null);
  // const [loadingSalesPeriod, setLoadingSalesPeriod] = useState(false);
  const [yearOptions, setYearOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [noOfAttendees, setNoOfAttendees] = useState(0);
  const [location, setLocation] = useState();
  const [bookingWindow, setBookingWindow] = useState();
  const [booking, setBooking] = useState();
  const [bookingOpeningDates, setBookingOpeningDates] = useState();
  const [selectedRoom, setSelectedRoom] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [selectedBookingItem, setSelectedBookingItem] = useState();
  const [isBookingReady, setIsBookingReady] = useState(false);
  const [bookingItems, setBookingItems] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [idCounter, setIdCounter] = useState(0);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const [isDuplicateBookingItem, setIsDuplicateBookingItem] = useState(false);
  const [isRoomCapacityReached, setIsRoomCapacityReached] = useState(false);
  const [isNumberOfAttendeesZero, setIsNumberOfAttendeesZero] = useState(false);
  const [entireDayCardDisabled, setEntireDayCardDisabled] = useState(false);

  //TODO: Make use of a list of errors instead of defining each one
  // const [validationErrors, setValidationErrors] = useState([]);

  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);
  const history = useHistory();

  //TODO: This must be configured somewhere!
  const timeSlot1StartDate = new Date();
  timeSlot1StartDate.setHours(7, 0, 0);

  const timeSlot1EndDate = new Date();
  timeSlot1EndDate.setHours(13, 0, 0);

  const timeSlot2StartDate = new Date();
  timeSlot2StartDate.setHours(13, 30, 0);

  const timeSlot2EndDate = new Date();
  timeSlot2EndDate.setHours(19, 0, 0);

  const timeSlot3StartDate = new Date();
  timeSlot3StartDate.setHours(7, 0, 0);

  const timeSlot3EndDate = new Date();
  timeSlot3EndDate.setHours(19, 0, 0);

  const timeslots = [
    {
      timeOfDay: "Morning",
      duration: "7am - 1pm",
      startTime: timeSlot1StartDate,
      endTime: timeSlot1EndDate,
    },
    {
      timeOfDay: "Afternoon",
      duration: "1:30pm - 7pm",
      startTime: timeSlot2StartDate,
      endTime: timeSlot2EndDate,
    },
    {
      timeOfDay: "Entire Day",
      duration: "7am - 7pm",
      startTime: timeSlot3StartDate,
      endTime: timeSlot3EndDate,
    },
  ];

  // useEffect(() => {
  //   async function fetchData() {
  //     setLoadingSalesPeriod(true);
  //     const currentSP = await getCurrentSalesPeriod();
  //     setCurrentSalesPeriod(currentSP);
  //     setLoadingSalesPeriod(false);
  //   }
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   getAllUniqueYears(setYearOptions, setLoading);
  // }, []);

  useEffect(() => {
    getLocations(setLocations, locations, setLoading);
  }, [locations, setLocations]);

  useEffect(() => {
    getBooking(setBooking, values.bookingId, setLoading, setBookingWindow);
  }, [values.bookingId, setBooking, setBookingWindow]);

  useEffect(() => {
    getLocation(setLocation, booking?.bookingWindow.locationId, setLoading);
  }, [booking, setLocation]);

  useEffect(() => {
    getBookingOpeningDates(
      booking?.userId,
      setLoading,
      setBookingOpeningDates,
      booking?.bookingWindowId
    );
  }, [selectedCustomerGroup, setBookingOpeningDates, booking?.bookingWindowId]);

  const areDatesEqual = (date1, date2) => {
    return date1.toDateString() === date2.toDateString();
  };

  const areTimesEqual = (date1, date2) => {
    return (
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes() &&
      date1.getSeconds() === date2.getSeconds()
    );
  };

  const convertToUTC = (date) => {
    const dateToConvert = new Date(date);

    const utcYear = dateToConvert.getUTCFullYear();
    const utcMonth = dateToConvert.getUTCMonth();
    const utcDay = dateToConvert.getUTCDate();
    const utcHours = dateToConvert.getUTCHours();
    const utcMinutes = dateToConvert.getUTCMinutes();
    const utcSeconds = dateToConvert.getUTCSeconds();

    return new Date(
      utcYear,
      utcMonth,
      utcDay,
      utcHours,
      utcMinutes,
      utcSeconds
    );
  };

  useEffect(() => {
    if (booking && bookingOpeningDates) {
      setNoOfAttendees(booking.attendeesCount);
      const items2 = [];

      booking.bookingItems.forEach((item, counter) => {
        const firstBookingItemStartDate = new Date(item?.startTime);
        const firstBookingItemEndDate = new Date(item?.endTime);
        const timeToReturn = timeslots?.find(
          (slot) =>
            areTimesEqual(
              firstBookingItemStartDate,
              convertToUTC(new Date(slot.startTime))
            ) &&
            areTimesEqual(
              firstBookingItemEndDate,
              convertToUTC(new Date(slot.endTime))
            )
        );

        const firstBookingItemDate = new Date(item?.startTime);
        const dayToReturn = bookingOpeningDates?.find((date) =>
          areDatesEqual(firstBookingItemDate, new Date(date.fullDate))
        );

        const fullDate = new Date(dayToReturn.fullDate);

        const startTimeNew = new Date(
          fullDate.getFullYear(),
          fullDate.getMonth(),
          fullDate.getDate(),
          timeToReturn?.startTime?.getHours(),
          timeToReturn?.startTime?.getMinutes(),
          timeToReturn?.startTime?.getSeconds()
        );

        const endTimeNew = new Date(
          fullDate.getFullYear(),
          fullDate.getMonth(),
          fullDate.getDate(),
          timeToReturn?.endTime?.getHours(),
          timeToReturn?.endTime?.getMinutes(),
          timeToReturn?.endTime?.getSeconds()
        );

        const timeSlotString = timeToReturn
          ? `${timeToReturn?.timeOfDay} (${timeToReturn?.duration})`
          : "";
        const dateString = `${moment(fullDate).format("dddd")} ${String(
          dayToReturn?.day
        ).padStart(2, "0")} ${moment(fullDate).format("MMMM")}`;

        const existingItem = {
          startTime: startTimeNew,
          endTime: endTimeNew,
          dateString: dateString,
          roomId: item.roomId,
          roomName: item.room.name,
          timeSlotString: timeSlotString,
          dayOfTheWeek: dayToReturn?.dayOfTheWeek,
          day: dayToReturn?.day,
          month: dayToReturn?.month,
          index: counter,
          dayObject: dayToReturn,
          timeSlotObject: timeToReturn,
          roomObject: item.room,
          bookingItemId: item.bookingItemId,
        };

        items2.push(existingItem);
        setIdCounter(counter);
      });

      setBookingItems(items2);
    }
  }, [booking, bookingOpeningDates]);

  useEffect(() => {
    if (selectedDay && selectedRoom && selectedTimeSlot && noOfAttendees) {
      setIsBookingReady(true);
    }
  }, [selectedDay, selectedRoom, selectedTimeSlot, noOfAttendees]);

  // useEffect(() => {
  //   if (currentSalesPeriod && yearOptions) {
  //     setSelectedITOYear(currentSalesPeriod?.name.substring(0, 4));
  //   }
  // }, [currentSalesPeriod, yearOptions]);

  useEffect(() => {
    if (yearOptions) {
      const currentYear = new Date().getFullYear();
      setSelectedITOYear(currentYear.toString());
    }
  }, [yearOptions]);

  const promptBeforeRedirect = (active) => {
    if (active) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  };

  useEffect(() => {
    promptBeforeRedirect(editing);
  }, [editing]);

  const handleTimeSlotCardClick = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);

    if (!noOfAttendees || noOfAttendees === 0) setIsNumberOfAttendeesZero(true);
    else setIsNumberOfAttendeesZero(false);

    if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
    if (entireDayCardDisabled) setEntireDayCardDisabled(false);
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setEditing(true);

    if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
    if (entireDayCardDisabled) setEntireDayCardDisabled(false);
  };

  const onRoomChange = (option) => {
    const room = location?.rooms?.find((room) => room.name === option);

    if (room) {
      setSelectedRoom(room);

      if (room.capacity < noOfAttendees) setIsRoomCapacityReached(true);
      else setIsRoomCapacityReached(false);
    }

    if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
  };

  const onAttendeesChange = (noOfAttendeesString) => {
    if (selectedRoom && selectedRoom.capacity < noOfAttendeesString?.floatValue)
      setIsRoomCapacityReached(true);
    else setIsRoomCapacityReached(false);

    if (!noOfAttendeesString || noOfAttendeesString?.floatValue === 0)
      setIsNumberOfAttendeesZero(true);
    else setIsNumberOfAttendeesZero(false);

    setNoOfAttendees(noOfAttendeesString?.floatValue);
    setEditing(true);
  };

  const addOrUpdateBooking = () => {
    if (selectedBookingItem) {
      const fullDate = new Date(selectedDay.fullDate);

      const startTimeNew = new Date(
        fullDate.getFullYear(),
        fullDate.getMonth(),
        fullDate.getDate(),
        selectedTimeSlot?.startTime?.getHours(),
        selectedTimeSlot?.startTime?.getMinutes(),
        selectedTimeSlot?.startTime?.getSeconds()
      );

      const endTimeNew = new Date(
        fullDate.getFullYear(),
        fullDate.getMonth(),
        fullDate.getDate(),
        selectedTimeSlot?.endTime?.getHours(),
        selectedTimeSlot?.endTime?.getMinutes(),
        selectedTimeSlot?.endTime?.getSeconds()
      );

      const dateString = `${moment(fullDate).format("dddd")} ${String(
        selectedDay?.day
      ).padStart(2, "0")} ${moment(fullDate).format("MMMM")}`;
      const timeSlotString = `${selectedTimeSlot.timeOfDay} (${selectedTimeSlot.duration})`;

      const bookingItem = {
        startTime: startTimeNew,
        endTime: endTimeNew,
        dateString: dateString,
        roomId: selectedRoom.roomId,
        roomName: selectedRoom.name,
        timeSlotString: timeSlotString,
        dayOfTheWeek: selectedDay?.dayOfTheWeek,
        day: selectedDay?.day,
        month: selectedDay?.month,
        index: idCounter,
        dayObject: selectedDay,
        timeSlotObject: selectedTimeSlot,
        roomObject: selectedRoom,
      };

      const isDuplicate = bookingItems.some(
        (item) =>
          item.roomId === bookingItem.roomId &&
          item.dateString === bookingItem.dateString &&
          item.timeSlotString === bookingItem.timeSlotString
      );

      if (isDuplicate) {
        setIsDuplicateBookingItem(true);
      } else {
        setIsDuplicateBookingItem(false);
      }

      if (!isDuplicate && bookingItems) {
        setIdCounter(idCounter + 1);

        const bookingItemToUpdate = bookingItems?.find(
          (item) => item.index === selectedBookingItem.index
        );

        const tempData = [...bookingItems];

        if (bookingItemToUpdate) {
          bookingItemToUpdate.startTime = startTimeNew;
          bookingItemToUpdate.endTime = endTimeNew;
          bookingItemToUpdate.dateString = dateString;
          bookingItemToUpdate.roomId = selectedRoom.roomId;
          bookingItemToUpdate.roomName = selectedRoom.name;
          bookingItemToUpdate.timeSlotString = timeSlotString;
          bookingItemToUpdate.dayOfTheWeek = selectedDay?.dayOfTheWeek;
          bookingItemToUpdate.day = selectedDay?.day;
          bookingItemToUpdate.month = selectedDay?.month;
          bookingItemToUpdate.dayObject = selectedDay;
          bookingItemToUpdate.timeSlotObject = selectedTimeSlot;
          bookingItemToUpdate.roomObject = selectedRoom;
          bookingItemToUpdate.index = selectedBookingItem.index;

          setBookingItems(tempData);
        }

        setSelectedDay(null);
        setSelectedRoom(null);
        setSelectedTimeSlot(null);
        setIsBookingReady(false);
        setSelectedBookingItem(null);
      }
    } else {
      const fullDate = new Date(selectedDay.fullDate);

      const startTimeNew = new Date(
        fullDate.getFullYear(),
        fullDate.getMonth(),
        fullDate.getDate(),
        selectedTimeSlot?.startTime?.getHours(),
        selectedTimeSlot?.startTime?.getMinutes(),
        selectedTimeSlot?.startTime?.getSeconds()
      );

      const endTimeNew = new Date(
        fullDate.getFullYear(),
        fullDate.getMonth(),
        fullDate.getDate(),
        selectedTimeSlot?.endTime?.getHours(),
        selectedTimeSlot?.endTime?.getMinutes(),
        selectedTimeSlot?.endTime?.getSeconds()
      );

      const dateString = `${moment(fullDate).format("dddd")} ${String(
        selectedDay?.day
      ).padStart(2, "0")} ${moment(fullDate).format("MMMM")}`;
      const timeSlotString = `${selectedTimeSlot.timeOfDay} (${selectedTimeSlot.duration})`;

      const maxId =
        bookingItems.length > 0
          ? Math.max(...bookingItems.map((item) => item.index))
          : 0;
      setIdCounter(maxId + 1);

      const bookingItem = {
        startTime: startTimeNew,
        endTime: endTimeNew,
        dateString: dateString,
        roomId: selectedRoom.roomId,
        roomName: selectedRoom.name,
        timeSlotString: timeSlotString,
        dayOfTheWeek: selectedDay?.dayOfTheWeek,
        day: selectedDay?.day,
        month: selectedDay?.month,
        index: maxId + 1,
        dayObject: selectedDay,
        timeSlotObject: selectedTimeSlot,
        roomObject: selectedRoom,
      };

      const isDuplicate = bookingItems.some(
        (item) =>
          item.roomId === bookingItem.roomId &&
          item.dateString === bookingItem.dateString &&
          item.timeSlotString === bookingItem.timeSlotString
      );

      if (isDuplicate) {
        setIsDuplicateBookingItem(true);
      } else {
        setIsDuplicateBookingItem(false);
      }

      if (!isDuplicate) {
        setIdCounter(idCounter + 1);

        const updatedItems = [...bookingItems, bookingItem].sort(
          (a, b) => a.startTime - b.startTime
        );

        setBookingItems(updatedItems);

        setSelectedDay(null);
        setSelectedRoom(null);
        setSelectedTimeSlot(null);
        setIsBookingReady(false);
        setSelectedBookingItem(null);
      }
    }
  };

  const clearSelection = () => {
    setSelectedDay(null);
    setSelectedRoom(null);
    setSelectedTimeSlot(null);
    setSelectedBookingItem(null);
    setIsBookingReady(false);
    setIsNumberOfAttendeesZero(false);
  };

  const submitBooking = async () => {
    setSaveLoading(true);

    const duration = `${moment(bookingWindow.startDate).format(
      "DD/MM/YYYY"
    )} - ${moment(bookingWindow.endDate).format("DD/MM/YYYY")}`;

    const bookingToUpdate = {
      bookingId: booking.bookingId,
      bookingWindowId: bookingWindow.bookingWindowId,
      bookingWindowName: bookingWindow?.name,
      bookingWindowLocation: bookingWindow?.location?.name,
      bookingWindowDuration: duration,
      userId: booking.userId,
      attendeesCount: noOfAttendees,
      specialRequests: booking.specialRequests ? booking.specialRequests : "",
      bookingAttendees: booking.bookingAttendees ? booking.bookingAttendees : [],
      bookingItems: bookingItems,
    };

    try {
      await updateBooking(bookingToUpdate);
      setSaveLoading(false);

      setSelectedDay(null);
      setSelectedRoom(null);
      setBookingItems([]);
      setNoOfAttendees(0);
      setSelectedTimeSlot(null);
      setIdCounter(0);
      setEditing(false);

      history.push({
        pathname: `/room-bookings/manage-booking/${values?.bookingId}`,
        state: {
          from: "/room-bookings/edit/:bookingId",
          booking: bookingToUpdate,
          customerGroupSelected: selectedCustomerGroup,
          itoYearSelected: selectedITOYear,
        },
      });
    } catch (err) {}
  };

  const removeBookingItem = (bookingItem) => {
    const filteredBookings = bookingItems.filter(
      (item) => item.index !== bookingItem.index
    );
    setBookingItems(filteredBookings);
  };

  const openBookingItem = (bookingItem) => {
    setSelectedDay(bookingItem.dayObject);
    setSelectedRoom(bookingItem.roomObject);
    setSelectedTimeSlot(bookingItem.timeSlotObject);
    setSelectedBookingItem(bookingItem);
  };

  const closeConfirmationModal = (confirmationChoice) => async () => {
    setConfirmationLoading(true);
    if (confirmationChoices.confirm === confirmationChoice) {
      history.push({
        pathname: `/room-bookings/manage-booking/${values?.bookingId}`,
        state: {
          from: "/room-bookings/edit/:bookingId",
          booking: booking,
          customerGroupSelected: selectedCustomerGroup,
          itoYearSelected: selectedITOYear,
        },
      });
    }
    setDeleteConfirmationModalOpen(false);
    setConfirmationLoading(false);
  };

  const handleImageModalOpen = () => setIsImageModalOpen(true);
  const handleImageModalClose = () => setIsImageModalOpen(false);

  return (
    <Form onSubmit={handleSubmit}>
      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        closeModal={closeConfirmationModal}
        confirmLoading={confirmationLoading}
        heading="Cancel Booking"
        content={
          <div className="div-booking-item">
            <Unicons.UilExclamationCircle color={"#FD7125"} size={25} /> You are
            about to cancel this booking and lose all the changes. Please
            confirm that you would like to perform this action.
          </div>
        }
      />
      <Helmet>
        <title>De Beers Room Bookings Portal - Room Bookings</title>
      </Helmet>
      <Grid>
        <Grid.Row className="customer-selection-header desktop">
          <Grid.Column
            computer={6}
            mobile={16}
            className="customer-group-heading"
          >
            {/* TODO: FIX THIS!! */}
            {/* Customer Group: {booking?.customerGroupName} */}
            Customer Group: A.Dalumi Diamonds Ltd.
          </Grid.Column>
          <Grid.Column computer={4} mobile={16}></Grid.Column>
          <Grid.Column computer={16} mobile={16}>
            <div className="border"></div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="mobile-view">
          <Grid.Column width={16}>
            <CustomerSelectionSlider
              //currentSight={currentSalesPeriod?.name}
              //loadingSalesPeriods={loading}
              //loadingCurrentSalesPeriod={loadingSalesPeriod}
              setSelectedYear={setSelectedITOYear}
              selectedYear={selectedITOYear}
              yearOptions={yearOptions}
              displayCustomer={false}
            />
          </Grid.Column>
        </Grid.Row>
        {bookingOpeningDates && bookingOpeningDates.length > 0 ? (
          <>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <StandardSmallHeading>
                  {bookingWindow?.name}
                </StandardSmallHeading>
                <StandardSubheading>Reserve your spot</StandardSubheading>
                <br></br>
                <Form>
                  <Form.Group width="equal" fluid>
                    <div className="div-col">
                      <Form.Field>
                        <label className="label-color">Location</label>
                      </Form.Field>
                      <Form.Field className="location-field">
                        {bookingWindow?.location?.name}
                      </Form.Field>
                    </div>

                    <NumberFormat
                      name="noOfAttendees"
                      isAllowed={(values) => {
                        const { floatValue } = values;
                        return floatValue >= 0 || !floatValue;
                      }}
                      onValueChange={(value) => onAttendeesChange(value)}
                      decimalScale={0}
                      label="Total Attendees"
                      customInput={Form.Input}
                      error={isNumberOfAttendeesZero || isRoomCapacityReached}
                      width={8}
                      value={noOfAttendees}
                      //TODO: FIX THIS
                      className="label-color" // Apply custom CSS class
                    />
                    {isNumberOfAttendeesZero && (
                      <Popup
                        content={
                          "The total attendees must be a number greater than zero."
                        }
                        Icon={Unicons.UilExclamationCircle}
                        iconClassNames="error-info-icon"
                      />
                    )}
                  </Form.Group>
                </Form>
                <StandardSmallHeading>Booking Selection</StandardSmallHeading>
                <Segment className="segment-padding-left">
                  <p className="p-text-colour">
                    Select a day
                  </p>
                  <Grid>
                    <Grid.Row columns={5}>
                      {bookingOpeningDates?.map((date, index) => (
                        <Grid.Column>
                          <BookingDayCard
                            date={date}
                            handleDayClick={handleDayClick}
                            selectedDay={selectedDay}
                          ></BookingDayCard>
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  </Grid>
                  <br />
                  <div>
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <p className="p-text-colour">
                            Select a room
                          </p>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                          <Label
                            basic
                            onClick={handleImageModalOpen}
                            className="view-map"
                          >
                            View Room Map
                          </Label>
                          <Modal
                            open={isImageModalOpen}
                            onClose={handleImageModalClose}
                            size="large"
                            closeIcon
                          >
                            <Header>Rooms Map</Header>
                            <Modal.Content>
                              <iframe
                                src={roomsMap}
                                width="100%"
                                height="630px"
                                title="Rooms Map"
                              />
                            </Modal.Content>
                          </Modal>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row columns={2}>
                        {location &&
                          location.rooms?.length > 0 &&
                          location.rooms?.map((room, index) => (
                            <Grid.Column className="column-room-padding">
                              <Segment
                              className={selectedRoom?.name === room.name 
                                ? "selected-room isSelected" 
                                : "selected-room isNotSelected"}
                              >
                                <Radio
                                  label={
                                    room.name +
                                    ` (Max ${room.capacity} attendee(s))`
                                  }
                                  value={room.name}
                                  checked={selectedRoom?.name === room.name}
                                  onChange={() => onRoomChange(room.name)}
                                  className="room-name-width"
                                />
                              </Segment>
                            </Grid.Column>
                          ))}
                      </Grid.Row>
                    </Grid>
                    <br />
                  </div>

                  <div>
                    <p className="p-text-colour">Select a time slot</p>
                    <Grid>
                      <Grid.Row columns={3}>
                        {timeslots &&
                          timeslots?.length > 0 &&
                          timeslots?.map((time, index) => (
                            <Grid.Column>
                              <BookingTimeSlotCard
                                handleTimeSlotCardClick={
                                  handleTimeSlotCardClick
                                }
                                selectedTimeSlot={selectedTimeSlot}
                                timeslot={time}
                              ></BookingTimeSlotCard>
                            </Grid.Column>
                          ))}
                      </Grid.Row>
                    </Grid>
                    <br />
                  </div>
                  {isDuplicateBookingItem && (
                    <Message error>
                      The same booking was already added to the summary.
                    </Message>
                  )}
                  {isRoomCapacityReached && (
                    <Message className="error-message-attendee-total" error>
                      The total number of attendees are more than the selected
                      room capacity. The room capacity for {selectedRoom?.name}{" "}
                      is {selectedRoom?.capacity}.
                    </Message>
                  )}
                  {isNumberOfAttendeesZero && (
                    <Message className="error-message-attendee-total" error>
                      The total attendees must be a number greater than zero.
                    </Message>
                  )}
                  <div className="right-text">
                    <Button secondary onClick={() => clearSelection()} className="btn-secondary">
                      Clear Selection
                    </Button>
                    <Button
                      disabled={
                        !isBookingReady ||
                        isRoomCapacityReached ||
                        isNumberOfAttendeesZero ||
                        isDuplicateBookingItem
                      }
                      primary
                      onClick={() => addOrUpdateBooking()}
                      className="btn-primary"
                    >
                      Update Booking
                    </Button>
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <StandardSmallHeading>Booking Summary</StandardSmallHeading>
                <br></br>
                {bookingItems && bookingItems.length > 0 ? (
                  <Segment>
                    <BookingItems
                      bookingItems={bookingItems}
                      removeBookingItem={removeBookingItem}
                      openBookingItem={openBookingItem}
                      selectedItem={selectedBookingItem}
                      isEditButtonVisible={true}
                    ></BookingItems>
                  </Segment>
                ) : (
                  <Segment className="segment-padding">
                    <p>Your Bookings will come here</p>
                  </Segment>
                )}
                <Button
                  disabled={bookingItems?.length === 0}
                  primary
                  floated="right"
                  onClick={submitBooking}
                  loading={saveLoading}
                  className="btn-primary"
                >
                  Submit Booking
                </Button>
                <Button
                  secondary
                  floated="right"
                  onClick={() => setDeleteConfirmationModalOpen(true)}
                  className="btn-secondary"
                >
                  Cancel
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </>
        ) : (
          <Message
            icon="warning circle"
            header={`No Booking Openings Configured`}
            content={` There are no booking openings configured for the selected
              customer group.`}
          />
        )}
      </Grid>
    </Form>
  );
};
