import {
  getCustomerGroup,
} from "../../lib/apiOrderManagement";

export const fetchCustomerGroup = async ({
  setCustomerGroup,
  customerGroupId,
}) => {
  if (customerGroupId !== null) {
    const customerGroup = await getCustomerGroup(customerGroupId);

    setCustomerGroup(customerGroup);
  }
};
