import React, { useState, useEffect, useContext } from "react";
import { Form } from "semantic-ui-react";
import { getUserCustomers } from "../../lib/apiUser";
import { getCustomer } from "../../lib/apiCustomers";
import {
  CustomerGroupsContext,
  SelectedCustomerGroupContext,
  SelectedCustomerContext,
} from "./CustomerGroupSelectorContext";
import "./CustomerGroupSelector.scss";
import { withRouter } from "react-router-dom";

const getCustomerGroupResults = async (
  setCustomerGroups,
  customerGroups,
  setLoading
) => {
  try {
    if (customerGroups == null || customerGroups.length === 0) {
      setLoading(true);
      const userCustomerGroups = await getUserCustomers();
      if (userCustomerGroups && userCustomerGroups.length > 0) {
        setCustomerGroups(userCustomerGroups);
      }
    }
  } finally {
    setLoading(false);
  }
};

const setCustomerGroupFromCustomerMetaData = async ({
  customerMetadata,
  setSelectedCustomerGroup,
}) => {
  const customer = await getCustomer(customerMetadata.value);

  setSelectedCustomerGroup(customer.customerGroupId);
};

export const CustomerGroupSelector = withRouter(
  ({
    hideCustomerSelector,
    showAllOption,
    location,
    hideHeading,
    className,
    setCurrentCustomer
  }) => {
    const { customerGroups, setCustomerGroups } = useContext(
      CustomerGroupsContext
    );
    const { selectedCustomerGroup, setSelectedCustomerGroup } = useContext(
      SelectedCustomerGroupContext
    );
    const { selectedCustomer, setSelectedCustomer } = useContext(
      SelectedCustomerContext
    );

    const [customerGroupOptions, setCustomerGroupOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (location.state?.customerMetadata) {
        setCustomerGroupFromCustomerMetaData({
          customerMetadata: location.state.customerMetadata,
          setSelectedCustomerGroup,
        });
      }
    }, [location.state, setSelectedCustomerGroup]);

    useEffect(() => {
      getCustomerGroupResults(setCustomerGroups, customerGroups, setLoading);
    }, [customerGroups, setCustomerGroups]);

    useEffect(() => {
      setCustomerGroupOptions(
        customerGroups.map((customerGroup) => {
          return {
            name: customerGroup.customerGroupName,
            key: customerGroup.customerGroupId,
            text: customerGroup.customerGroupName,
            value: customerGroup.customerGroupId,
          };
        })
      );
    }, [customerGroups, setCustomerGroupOptions]);

    useEffect(() => {
      if (
        customerGroups &&
        customerGroups.length > 0 &&
        selectedCustomerGroup === null
      ) {
        setSelectedCustomerGroup(customerGroups[0].customerGroupId);
      }
    }, [customerGroups, selectedCustomerGroup, setSelectedCustomerGroup]);

    useEffect(() => {
      const tempCustomer = {
        selectedCustomer: selectedCustomer,
        selectedCustomerGroup: selectedCustomerGroup
      };
      const customerGroup = customerGroups.find(
        (customerGroup) =>
          customerGroup.customerGroupId === selectedCustomerGroup
      );

      if (
        customerGroup &&
        customerGroup.customers &&
        customerGroup.customers.length > 0
      ) {
        const customerOptions = customerGroup.customers.map((customer) => {
          return {
            name: customer.customerName,
            key: customer.customerId,
            text: customer.customerName + " (" + customer.location + ")",
            value: customer.customerId,
          };
        });
        if (showAllOption) {
          customerOptions.unshift({
            name: "View all",
            key: 0,
            text: "View all",
            value: 0,
          });
        }
        setCustomerOptions(customerOptions);

        if (
          location.state?.customerMetadata &&
          !isNaN(parseInt(location.state.customerMetadata.value))
        ) {
          setSelectedCustomer(parseInt(location.state.customerMetadata.value));
          tempCustomer.selectedCustomer = parseInt(location.state.customerMetadata.value);
        } else if (
          !location.state?.customerMetadata &&
          (selectedCustomer === null ||
            customerOptions.every((opt) => opt.value !== selectedCustomer))
        ) {
          setSelectedCustomer(customerOptions[0].value);
          tempCustomer.selectedCustomer = customerOptions[0].value
        }
      } else {
        setCustomerOptions([]);
      }
      if(setCurrentCustomer){
          setCurrentCustomer(tempCustomer);
      }
    }, [
      selectedCustomerGroup,
      selectedCustomer,
      customerGroups,
      showAllOption,
      setSelectedCustomer,
      location.state,
      setCurrentCustomer
    ]);

    const customerGroupWidth = hideCustomerSelector ? 16 : 8; //make this 16 when the customer dropdown is hidden to remove the space fro the customer dropdown

    return (
      <Form className={["customer-group-selector", className].join(" ")}>
        <Form.Group>
          <Form.Dropdown
            // TODO: Fix colour (outline)
            //className="balck-outline-dropdown"
            width={customerGroupWidth}
            label={!hideHeading && "Sightholder"}
            id="selected-customer-group"
            placeholder="Customer Group"
            onChange={(_, { value }) => setSelectedCustomerGroup(value)}
            search
            selection
            value={selectedCustomerGroup}
            options={customerGroupOptions}
            loading={loading}
          />
          {!hideCustomerSelector && (
            <Form.Dropdown
              // TODO: Fix colour (outline)
              //className="balck-outline-dropdown"
              width={12}
              label="Customer (Location)"
              id="selected-customer"
              placeholder="Customer"
              onChange={(_, { value }) => setSelectedCustomer(value)}
              search
              selection
              value={selectedCustomer}
              options={customerOptions}
              loading={loading}
            />
          )}
        </Form.Group>
      </Form>
    );
  }
);

export default CustomerGroupSelector;
