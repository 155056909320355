import axios from "axios";
import { getToken, logout } from "./authentication";
import { serializeError } from "serialize-error";

export const createAxios = (url) => {
  const statusActions = {
    401: () => {
      // TODO: Uncomment this!!
      //logout();
    },
  };

  const token = getToken();

  const axiosInstance = axios.create({
    baseURL: url,
  });

  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  axiosInstance.interceptors.response.use(
    (request) => request,
    (error) => {
      if (!axios.isCancel(error)) {
        const err = serializeError(error);
        const action = statusActions[err.response.status];
        if (action) {
          action();
        }
        throw error;
      }
    }
  );

  return axiosInstance;
};

export const axiosSubscription = (setCancelToken) => {
  const cancelTokenObject = {
    isSubscribed: true,
    httpRequestCancelToken: axios.CancelToken.source(),
  };

  setCancelToken((prevState) => {
    if (prevState !== null) {
      prevState.isSubscribed = false;
      prevState.httpRequestCancelToken.cancel(
        "Operation canceled due to new request."
      );
    }
    return cancelTokenObject;
  });

  return cancelTokenObject;
};
