import "./UpcomingBookings.scss";
import React, { useState, useEffect } from "react";
import { Grid, Segment, Divider, Button } from "semantic-ui-react";
import { LoaderComponent } from "../../common/LoaderComponent";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import * as Unicons from "@iconscout/react-unicons";
import { getBookingDataAsync } from "../../lib/apiBookings";
import { Link } from "react-router-dom";
import { Popup } from "../../common/Popup/Popup";

/*
TODO: Move inline styling
*/

const getBookingData = async (
  selectedCustomerGroup,
  selectedItoYear,
  setLoadingBookingData,
  setBookingData
) => {
  setLoadingBookingData(true);

  const data = await getBookingDataAsync(
    selectedCustomerGroup,
    selectedItoYear
  );

  await setBookingData(data);

  setLoadingBookingData(false);
};

export const UpcomingBookings = ({
  selectedCustomerGroup,
  selectedItoYear,
}) => {
  const [loadingBookingData, setLoadingBookingData] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  //TODO: FIX!!
  selectedCustomerGroup = 107;
  useEffect(() => {
    if (selectedCustomerGroup && selectedItoYear) {
      getBookingData(
        selectedCustomerGroup,
        selectedItoYear,
        setLoadingBookingData,
        setBookingData
      );
    }
  }, [selectedCustomerGroup, selectedItoYear]);

  const checkAttendeeNames = (booking) => {
    return booking?.attendees?.every(
      (attendee) =>
        attendee.firstName.trim() === "" && attendee.lastName.trim() === ""
    );
  };

  return (
    <LoaderComponent loading={loadingBookingData}>
      <StandardSubheading>Upcoming Bookings</StandardSubheading>

      <Divider className="divider-colour"/>
      {bookingData?.length > 0 ? (
        bookingData?.map((booking, index) => (
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <div className="div-booking-item">
                    <h1 className="booking-h1-heading">
                      {booking.bookingWindowName}
                    </h1>
                    <br></br>
                    {checkAttendeeNames(booking) && (
                      <Popup
                        content={"Please add attendee names to this booking."}
                        Icon={Unicons.UilExclamationCircle}
                        iconClassNames="error-info-icon"
                      />
                    )}
                  </div>
                  <p className="p-sight-week-date">
                    {booking.bookingWindowDuration}
                  </p>
                </Grid.Column>
                <Grid.Column width={8}>
                  {/* TODO: cater for mobile as well */}
                  <Button
                    secondary
                    floated="right"
                    Icon={Unicons.UilEditAlt}
                    as={Link}
                    to={`/room-bookings/manage-booking/${booking?.bookingId}`}
                    className="btn-secondary"
                  >
                    Manage
                  </Button>
                </Grid.Column>
              </Grid.Row>
              {booking?.bookingItemsForDisplay?.map((item, itemIndex) => (
                <Grid.Row>
                  <Grid.Column width={2}>
                    <div>
                      <p className="p-booking-item-week-day">
                        {item.dayOfTheWeek}
                      </p>
                      <h2 className="h2-booking-item-day">{item.day}</h2>
                      <p className="p-booking-item-week-day">{item.month}</p>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <div className="div-booking-item">
                      {" "}
                      <Unicons.UilLocationPoint className="icon-booking-item" />
                      <p>
                        {booking.bookingWindowLocation}, {item.roomName}
                      </p>
                    </div>
                    <br></br>
                    <div className="div-booking-item">
                      <Unicons.UilClockThree className="icon-booking-item" />
                      <p>{item.timeSlot}</p>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          </Segment>
        ))
      ) : (
        <Segment>
          <p>There are no bookings for the current year.</p>
        </Segment>
      )}
    </LoaderComponent>
  );
};

export default UpcomingBookings;
