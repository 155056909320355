import { notifyError } from "./notifications";

const { createAxios } = require("./axiosWrapper");

// TODO: Replace Temp with actual controller!!
const locationUrl = `${process.env.REACT_APP_GSS_ROOM_BOOKINGS_API_URL}/api/TempLocation`;

const axios = () => createAxios(locationUrl);

export const getLocationsAsync = async (
) => {
  try {
    const requestUrl =
      ``;

    const locations = await axios().get(requestUrl);

    return locations.data ? locations.data : [];
  } catch (err) {
    notifyError("Failed to get the locations.", err);
  }
};

export const getLocationAsync = async (
  locationId
) => {
  try {
    const requestUrl =
      `get-location-by-id?locationId=${locationId}`;

    const location = await axios().get(requestUrl);

    return location.data ? location.data : [];
  } catch (err) {
    notifyError("Failed to get the location.", err);
  }
};


