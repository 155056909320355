import { notifyError } from "./notifications";

const { createAxios } = require("./axiosWrapper");

// TODO: Replace Temp with actual controller!!
const bookingUrl = `${process.env.REACT_APP_GSS_ROOM_BOOKINGS_API_URL}/api/TempBooking`;

const axios = () => createAxios(bookingUrl);

export const getBookingDataAsync = async (customerGroupId, selectedItoYear, isPast) => {
  try {
    const requestUrl = `get-bookings-for-customer-group?customerGroupId=${customerGroupId}&itoYear=${selectedItoYear}`;

    const bookings = await axios().get(requestUrl);

    return bookings.data ? bookings.data : [];
  } catch (err) {
    notifyError("Failed to get the bookings.", err);
  }
};

export const getPastBookingDataAsync = async (customerGroupId, selectedItoYear, isPast) => {
  try {
    const requestUrl = `get-past-bookings-for-customer-group?customerGroupId=${customerGroupId}&itoYear=${selectedItoYear}&isPast=${isPast}`;

    const bookings = await axios().get(requestUrl);

    return bookings.data ? bookings.data : [];
  } catch (err) {
    notifyError("Failed to get the bookings.", err);
  }
};

export const getBookingAsync = async (
  bookingId
) => {
  try {
    const requestUrl =
      `get-booking-by-id?bookingId=${bookingId}`;

    const booking = await axios().get(requestUrl);

    return booking.data ? booking.data : [];
  } catch (err) {
    notifyError("Failed to get the booking.", err);
  }
};


export const createBooking = async (booking) => {
  try {
    const createdBooking = await axios().post("", booking, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return createdBooking.data ? createdBooking.data : [];
  } catch (err) {
    notifyError("Failed to create the booking.", err);
  }
};

export const updateBooking = async (booking) => {
  try {
    const updatedBooking = await axios().put("", booking, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return updatedBooking.data ? updatedBooking.data : [];
  } catch (err) {
    notifyError("Failed to update the booking.", err);
  }
};

export const deleteBooking = async (bookingId) => {
  try {
    const response = await axios().delete(
      `delete?id=${bookingId}`
    );
  } catch (err) {
    notifyError("Failed to delete the booking.", err);
  }
};