import { createAxios } from "./axiosWrapper";
import { notifyError } from "./notifications";

const customerApiUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api`;
const axios = () => createAxios(customerApiUrl);

export const getCustomer = async (customerId) => {
  try {
    //TODO: undo these changes
    var customerData = {
      "customerId": 275,
      "customerName": "A.Dalumi Botswana",
      "salesLocation": {
        "salesLocationId": 3,
        "salesLocationName": "De Beers Botswana",
        "salesLocationReference": "6d6a6220-5cf7-44e7-922a-d363ab6c1513",
        "weighting": 90.00,
        "salesLocationCode": 6
      },
      "salesLocationId": 3,
      "createdByUserId": null,
      "axReferenceCode": "10025",
      "customerGroupId": 107,
      "isActive": true,
      "brokerageId": null,
      "representativeCustomers": [
        {
          "representativeCustomerId": 7,
          "representativeId": 23,
          "customerId": 275,
          "representative": {
            "representativeId": 23,
            "representativeTypeId": 4,
            "title": null,
            "firstName": "Jane",
            "lastName": "Doe",
            "axRepresentativeCode": null,
            "isActive": true,
            "dateCreated": "2024-11-04T06:54:25.4163266",
            "representativeRef": "e78b6b4f-2fba-48ba-8620-57899ac99b4b",
            "representativeTypeDescription": "Customer"
          }
        }
      ]
    }
    return customerData;
    // const requestUrl = `/customers/${customerId}`;

    // const customer = await axios().get(requestUrl);
    // return customer.data;
  } catch (err) {
    notifyError("Failed to fetch the customer", err);
  }
};
