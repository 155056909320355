import "./BookingCompleted.scss";
import React from "react";
import { Grid, Segment, Button } from "semantic-ui-react";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import * as Unicons from "@iconscout/react-unicons";
import CommonHeader from "./CommonHeader";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export const BookingCompleted = (props) => {
  const booking = props.location.state.booking;
  const bookingId = props.location.state.bookingId;
  //const customerGroupSelected = props.location.state.customerGroupSelected;
  //TODO: FIX THIS!!
  const customerGroupSelected = "Jane";

  const itoYearSelected = props.location.state.customerGroupSelected;
  const history = useHistory();
   
  const onManageButtonClick = () => {
    history.push({
      pathname: `/room-bookings/manage-booking/${bookingId}`,
      state: {
        from: "/room-bookings/booking-created-success",
      },
    });
   }

  return (
    <div>
      <Grid
        centered
        className="center-items"
      >
        <Grid.Row>
          <Grid.Column computer={6} mobile={12}>
            <div className="center-text">
              <Unicons.UilCheckCircle className="icon-booking-item" size={48} />
              <StandardSubheading>
                Booking completed for {customerGroupSelected}
              </StandardSubheading>
              <br></br>
              <p>We have received your booking. Please click on Manage Booking to enter the names of all the attendees.</p>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={6} mobile={16}>
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column computer={10} mobile={24}>
                    <div>
                      <StandardSmallHeading>
                        {booking.bookingWindowName}
                      </StandardSmallHeading>
                      <p className="p-sight-week-date">
                        {booking.bookingWindowDuration}
                      </p>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                {booking?.bookingItems?.map((item, itemIndex) => (
                  <Grid.Row>
                    <Grid.Column computer={2} mobile={4}>
                      <div>
                        <p className="p-booking-item-week-day">
                          {item.dayOfTheWeek}
                        </p>
                        <h2 className="h2-booking-item-day">{item.day}</h2>
                        <p className="p-booking-item-week-day">{item.month}</p>
                      </div>
                    </Grid.Column>
                    <Grid.Column computer={10} mobile={24}>
                      <div className="div-booking-item">
                        {" "}
                        <Unicons.UilLocationPoint className="icon-booking-item" />
                        <p>
                          {booking.bookingWindowLocation}, {item.roomName}
                        </p>
                      </div>
                      <br></br>
                      <div className="div-booking-item">
                        {" "}
                        <Unicons.UilClockThree className="icon-booking-item" />
                        <p>{item.timeSlotString}</p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={6} mobile={6}>
            <Button
              secondary
              fluid
              onClick={onManageButtonClick}
              className="btn-secondary"
            >
              Manage Booking
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default BookingCompleted;
