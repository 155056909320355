import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Grid, Segment, Button, Icon } from "semantic-ui-react";
import { CustomerGroupSelector } from "../../common/CustomerGroupSelector/CustomerGroupSelector";
// import { getCurrentSalesPeriod } from "../../lib/apiSalesPeriod";
import { CustomerSelectionSlider } from "../../common-mobile/CustomerSelectionSlider/CustomerSelectionSlider";
import UpcomingBookings from "./UpcomingBookings";
import FutureBookings from "./FutureBookings";
import { SelectedCustomerGroupContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { Link } from "react-router-dom";

export const RoomBookingsContainer = () => {
  // const [currentSalesPeriod, setCurrentSalesPeriod] = useState(null);
  const [selectedITOYear, setSelectedITOYear] = useState(null);
  // const [loadingSalesPeriod, setLoadingSalesPeriod] = useState(false);
  const [yearOptions, setYearOptions] = useState([]);
  // const [loading, setLoading] = useState(false);

  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);

  // useEffect(() => {
  //   async function fetchData() {
  //     //setLoadingSalesPeriod(true);
  //     const currentSP = await getCurrentSalesPeriod();
  //     setCurrentSalesPeriod(currentSP);
  //     //setLoadingSalesPeriod(false);
  //   }
  //   fetchData();
  // }, []);


  // useEffect(() => {
  //   if (currentSalesPeriod && yearOptions) {
  //     //setSelectedITOYear(currentSalesPeriod?.name.substring(0, 4));
  //     setSelectedITOYear("2024");
  //   }
  // }, [currentSalesPeriod, yearOptions]);

  useEffect(() => {
    if (yearOptions) {
      const currentYear = new Date().getFullYear();
      setSelectedITOYear(currentYear.toString());
    }
  }, [yearOptions]);

  return (
    <>
      <Helmet>
        <title>De Beers Room Bookings Portal - Room Bookings</title>
      </Helmet>
      <Grid>
        <Grid.Row className="customer-selection-header desktop">
          <Grid.Column computer={4} mobile={16}>
            <CustomerGroupSelector hideCustomerSelector={true} />
          </Grid.Column>
          <Grid.Column
            computer={4}
            mobile={16}
            className="current-sight-column"
          >
          </Grid.Column>
          <Grid.Column computer={16} mobile={16}>
            <div className="border"></div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="mobile-view">
          <Grid.Column width={16}>
            <CustomerSelectionSlider
              //currentSight={currentSalesPeriod?.name}
              //loadingSalesPeriods={loading}
              //loadingCurrentSalesPeriod={loadingSalesPeriod}
              setSelectedYear={setSelectedITOYear}
              selectedYear={selectedITOYear}
              yearOptions={yearOptions}
              displayCustomer={false}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Segment>
              <UpcomingBookings
                selectedCustomerGroup={selectedCustomerGroup}
                selectedItoYear={selectedITOYear}
              ></UpcomingBookings>
              <br></br>
              <Button
                fluid
                secondary
                as={Link}
                to={`/room-bookings/past`}
                className="btn-secondary"
              >
                View all past bookings
              </Button>
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <Segment>
                <FutureBookings
                  selectedItoYear={selectedITOYear}
                ></FutureBookings>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default RoomBookingsContainer;
