import "./BookingDayCard.scss";
import React from "react";
import { Card } from "semantic-ui-react";

export const BookingDayCard = ({ date, handleDayClick, selectedDay }) => {
  return (
      <div>
    {/* TODO: Move to individual stylesheet */}
      <Card
        className="booking-card"
        onClick={() => {
          handleDayClick(date);
        }}
        style={{
          cursor: "pointer",
          padding: "14px",
          backgroundColor:
            date === selectedDay ? "#e8e8e8" : "white",
          borderRadius: "0px",
          boxShadow:  date === selectedDay ? "0 0 0 1px black" : "0 1px 3px 0 #d4d4d5,0 0 0 1px #d4d4d5",
          marginBottom: "20px"
        }}
      >
        <div>
          <p className="p-booking-item-week-day">{date.dayOfTheWeek}</p>
          <h2 className="h2-booking-item-day">{date.day}</h2>
          <p className="p-booking-item-week-day">{date.month}</p>
        </div>
      </Card>
    </div>
  );
};

export default BookingDayCard;
