import "./RoomBookingsCreateContainer.scss";

import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import {
  Grid,
  Segment,
  Button,
  Form,
  Radio,
  Message,
  Label,
  Image,
  Modal,
  Header
} from "semantic-ui-react";
import * as Unicons from "@iconscout/react-unicons";
import { CustomerGroupSelector } from "../../common/CustomerGroupSelector/CustomerGroupSelector";
// import { getCurrentSalesPeriod } from "../../lib/apiSalesPeriod";
import { CustomerSelectionSlider } from "../../common-mobile/CustomerSelectionSlider/CustomerSelectionSlider";
import { SelectedCustomerGroupContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import BookingDayCard from "./Cards/BookingDayCard";
import BookingTimeSlotCard from "./Cards/BookingTimeSlotCard";
import { getLocationsAsync, getLocationAsync } from "../../lib/apiLocations";
import { getBookingOpeningDatesAsync } from "../../lib/apiBookingOpenings";
import { getBookingWindowAsync } from "../../lib/apiBookingWindows";
import { createBooking } from "../../lib/apiBookings";
import BookingItems from "./BookingItems";
import { useHistory, Prompt } from "react-router-dom";
import moment from "moment";
import { getCustomerGroup } from "../../lib/apiOrderManagement";
import NumberFormat from "react-number-format";
import { Popup } from "../../common/Popup/Popup";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import {
  ConfirmationModal,
  confirmationChoices,
} from "../../common/ConfirmationModal/ConfirmationModal";
import roomsMap from "../../media/gss-room-bookings/revB_DBG-booking-room-map.pdf";
import { notifyError } from "../../lib/notifications";

/*
TODO: Clean up file:
- NEED TO MOVE TO INDIVIDUAL STYLESHEETS.
- NEED TO DOUBLE CHECK THAT ALL STATE VARIABLES ARE NEEDED
- NEED TO ALSO CHECK THAT COMPONENTS MAKE SENSE
- CATER FOR MOBILE
- ADD SIGNAL R AND CHECK ROOM AVAILABILITY
- ADD VALIDATIONS TO ENSURE YOU CANNOT BOOK A DAY IN THE PAST 
*/

const getLocations = async (setLocations, locations, setLoading) => {
  try {
    if (locations == null || locations?.length === 0) {
      setLoading(true);
      const locationResponse = await getLocationsAsync();
      if (locationResponse && locationResponse.length > 0) {
        setLocations(locationResponse);
      }
    }
  } finally {
    setLoading(false);
  }
};

const getLocation = async (setLocation, location, setLoading) => {
  try {
    if (location) {
      setLoading(true);
      const locationResponse = await getLocationAsync(location);
      if (locationResponse) {
        setLocation(locationResponse);
      }
    }
  } finally {
    setLoading(false);
  }
};

const getBookingWindow = async (
  setBookingWindow,
  bookingWindowId,
  setLoading
) => {
  try {
    setLoading(true);
    const bookingWindowResponse = await getBookingWindowAsync(bookingWindowId);
    if (bookingWindowResponse) {
      setBookingWindow(bookingWindowResponse);
    }
  } finally {
    setLoading(false);
  }
};

const getBookingOpeningDates = async (
  selectedCustomerGroup,
  setLoading,
  setBookingOpeningDates,
  bookingWindowId
) => {
  setLoading(true);
    //TODO: GET CUSTOMER GROUP...
  selectedCustomerGroup = 107;
  if (selectedCustomerGroup && bookingWindowId) {
    const data = await getBookingOpeningDatesAsync(
      selectedCustomerGroup,
      bookingWindowId
    );

    await setBookingOpeningDates(data);
  }

  setLoading(false);
};

export const fetchCustomerGroup = async (setCustomerGroup, customerGroupId) => {
  //if (customerGroupId) {
    const customerGroup = await getCustomerGroup(customerGroupId);
    /* eslint-disable no-console */
    console.log(customerGroup);
    setCustomerGroup(customerGroup);

    return customerGroup;
  //}
};

export const RoomBookingsCreateContainer = (
  {
  match: {
    params: { bookingWindowId },
  },
}) => {
  // const [currentSalesPeriod, setCurrentSalesPeriod] = useState(null);
  const [selectedITOYear, setSelectedITOYear] = useState(null);
  // const [loadingSalesPeriod, setLoadingSalesPeriod] = useState(false);
  const [yearOptions, setYearOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [noOfAttendees, setNoOfAttendees] = useState(0);
  const [location, setLocation] = useState();
  const [bookingWindow, setBookingWindow] = useState();
  const [bookingOpeningDates, setBookingOpeningDates] = useState();
  const [selectedRoom, setSelectedRoom] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [isRoomPanelVisible, setIsRoomPanelVisible] = useState(false);
  const [isTimeSlotPanelVisible, setIsTimeSlotPanelVisible] = useState(false);
  const [isBookingReady, setIsBookingReady] = useState(false);
  const [bookingItems, setBookingItems] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [customerGroup, setCustomerGroup] = useState();
  const [idCounter, setIdCounter] = useState(0);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const [isDuplicateBookingItem, setIsDuplicateBookingItem] = useState(false);
  const [isRoomCapacityReached, setIsRoomCapacityReached] = useState(false);
  const [isNumberOfAttendeesZero, setIsNumberOfAttendeesZero] = useState(false);
  const [isLocationPopulated, setIsLocationPopulated] = useState(false);
  const [entireDayCardDisabled, setEntireDayCardDisabled] = useState(false);
  //TODO: Make use of a list of errors instead of defining each one
  // const [validationErrors, setValidationErrors] = useState([]);

  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);
  const history = useHistory();

  // useEffect(() => {
  //   async function fetchData() {
  //     setLoadingSalesPeriod(true);
  //     const currentSP = await getCurrentSalesPeriod();
  //     setCurrentSalesPeriod(currentSP);
  //     setLoadingSalesPeriod(false);
  //   }
  //   fetchData();
  // }, []);

  useEffect(() => {
    fetchCustomerGroup(setCustomerGroup, selectedCustomerGroup);
  }, []);

  useEffect(() => {
    getLocations(setLocations, locations, setLoading);
  }, [locations, setLocations]);

  useEffect(() => {
    getLocation(setLocation, selectedLocation, setLoading);
    getBookingWindow(setBookingWindow, bookingWindowId, setLoading);
  }, [selectedLocation, setLocation, bookingWindowId, setBookingWindow]);

  useEffect(() => {
    getBookingOpeningDates(
      selectedCustomerGroup,
      setLoading,
      setBookingOpeningDates,
      bookingWindowId
    );
  }, [selectedCustomerGroup, setBookingOpeningDates, bookingWindowId]);

  //TODO: Do I DO THIS LIKE THIS??
  useEffect(() => {
    if (
      //selectedLocation &&
      selectedDay &&
      selectedRoom &&
      selectedTimeSlot &&
      noOfAttendees
    ) {
      setIsBookingReady(true);
    }
  }, [
    selectedLocation,
    selectedDay,
    selectedRoom,
    selectedTimeSlot,
    noOfAttendees,
  ]);

  // useEffect(() => {
  //   if (currentSalesPeriod && yearOptions) {
  //     setSelectedITOYear(currentSalesPeriod?.name.substring(0, 4));
  //   }
  // }, [currentSalesPeriod, yearOptions]);

  useEffect(() => {
    if (yearOptions) {
      const currentYear = new Date().getFullYear();
      setSelectedITOYear(currentYear.toString());
    }
  }, [yearOptions]);
  

  useEffect(() => {
    if (locations) {
      setLocationOptions(
        locations.map((location) => {
          return {
            name: location.name,
            key: location.locationId,
            text: location.name,
            value: location.locationId,
          };
        })
      );

      setIsLocationPopulated(true);
    }
  }, [locations, setLocationOptions]);

  const promptBeforeRedirect = (active) => {
    if (active) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  };

  useEffect(() => {
    promptBeforeRedirect(editing);
  }, [editing]);

  //TODO: This must be configured somewhere!
  const timeSlot1StartDate = new Date();
  timeSlot1StartDate.setHours(7, 0, 0);

  const timeSlot1EndDate = new Date();
  timeSlot1EndDate.setHours(13, 0, 0);

  const timeSlot2StartDate = new Date();
  timeSlot2StartDate.setHours(13, 30, 0);

  const timeSlot2EndDate = new Date();
  timeSlot2EndDate.setHours(19, 0, 0);

  const timeSlot3StartDate = new Date();
  timeSlot3StartDate.setHours(7, 0, 0);

  const timeSlot3EndDate = new Date();
  timeSlot3EndDate.setHours(19, 0, 0);

  const timeslots = [
    {
      timeOfDay: "Morning",
      duration: "7am - 1pm",
      startTime: timeSlot1StartDate,
      endTime: timeSlot1EndDate,
    },
    {
      timeOfDay: "Afternoon",
      duration: "1:30pm - 7pm",
      startTime: timeSlot2StartDate,
      endTime: timeSlot2EndDate,
    },
    {
      timeOfDay: "Entire Day",
      duration: "7am - 7pm",
      startTime: timeSlot3StartDate,
      endTime: timeSlot3EndDate,
    },
  ];

  const handleTimeSlotCardClick = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);

    if (!noOfAttendees || noOfAttendees === 0) setIsNumberOfAttendeesZero(true);
    else setIsNumberOfAttendeesZero(false);

    if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
    if (entireDayCardDisabled) setEntireDayCardDisabled(false);
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setIsRoomPanelVisible(true);
    setEditing(true);

    if (location) setIsLocationPopulated(true);
    else setIsLocationPopulated(false);

    if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
    if (entireDayCardDisabled) setEntireDayCardDisabled(false);
  };

  const onRoomChange = (option) => {
    setIsTimeSlotPanelVisible(true);

    const room = locations[0]?.rooms?.find((room) => room.name === option);

    if (room) {
      setSelectedRoom(room);

      if (room.capacity < noOfAttendees) setIsRoomCapacityReached(true);
      else setIsRoomCapacityReached(false);
    }

    if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
  };

  const onLocationChange = (location) => {
    setSelectedLocation(location);
    setIsLocationPopulated(true);
    setEditing(true);

    if (isDuplicateBookingItem) setIsDuplicateBookingItem(false);
    if (entireDayCardDisabled) setEntireDayCardDisabled(false);
  };

  const onAttendeesChange = (noOfAttendeesString) => {
    if (selectedRoom && selectedRoom.capacity < noOfAttendeesString?.floatValue)
      setIsRoomCapacityReached(true);
    else setIsRoomCapacityReached(false);

    if (!noOfAttendeesString || noOfAttendeesString?.floatValue === 0)
      setIsNumberOfAttendeesZero(true);
    else setIsNumberOfAttendeesZero(false);

    setNoOfAttendees(noOfAttendeesString?.floatValue);
    console.log(noOfAttendeesString?.floatValue);
    setEditing(true);
  };

  const addBooking = () => {
    const fullDate = new Date(selectedDay.fullDate);

    const startTimeNew = new Date(
      fullDate.getFullYear(),
      fullDate.getMonth(),
      fullDate.getDate(),
      selectedTimeSlot?.startTime?.getHours(),
      selectedTimeSlot?.startTime?.getMinutes(),
      selectedTimeSlot?.startTime?.getSeconds()
    );

    const endTimeNew = new Date(
      fullDate.getFullYear(),
      fullDate.getMonth(),
      fullDate.getDate(),
      selectedTimeSlot?.endTime?.getHours(),
      selectedTimeSlot?.endTime?.getMinutes(),
      selectedTimeSlot?.endTime?.getSeconds()
    );

    const dateString = `${moment(fullDate).format("dddd")} ${String(
      selectedDay?.day
    ).padStart(2, "0")} ${moment(fullDate).format("MMMM")}`;
    const timeSlotString = `${selectedTimeSlot.timeOfDay} (${selectedTimeSlot.duration})`;

    const bookingItem = {
      startTime: startTimeNew,
      endTime: endTimeNew,
      dateString: dateString,
      roomId: selectedRoom.roomId,
      roomName: selectedRoom.name,
      timeSlotString: timeSlotString,
      dayOfTheWeek: selectedDay?.dayOfTheWeek,
      day: selectedDay?.day,
      month: selectedDay?.month,
      index: idCounter,
    };

    const isDuplicate = bookingItems.some(
      (item) =>
        item.roomId === bookingItem.roomId &&
        item.dateString === bookingItem.dateString &&
        item.timeSlotString === bookingItem.timeSlotString
    );

    //TODO: VALIDATE THIS RULE! IT MIGHT BE THE OTHER WAY AROUND
    const isBookingItemMorningOrAfternoon = bookingItems.some(
      (item) =>
        item.dateString === bookingItem.dateString &&
        bookingItem.timeSlotString.includes("Entire") &&
        (item.timeSlotString.includes("Morning") ||
          item.timeSlotString.includes("Afternoon"))
    );

    if (isBookingItemMorningOrAfternoon) setEntireDayCardDisabled(true);
    else setEntireDayCardDisabled(false);

    if (isDuplicate) {
      setIsDuplicateBookingItem(true);
    } else {
      setIsDuplicateBookingItem(false);
    }

    if (!isDuplicate && !isBookingItemMorningOrAfternoon) {
      setIdCounter(idCounter + 1);

      const updatedItems = [...bookingItems, bookingItem].sort(
        (a, b) => a.startTime - b.startTime
      );

      setBookingItems(updatedItems);

      setSelectedDay(null);
      setSelectedRoom(null);
      setSelectedTimeSlot(null);
      setIsBookingReady(false);
      //setNoOfAttendees(0);
    }
  };

  const submitBooking = async () => {
    setSaveLoading(true);

    const duration = `${moment(bookingWindow.startDate).format(
      "DD/MM/YYYY"
    )} - ${moment(bookingWindow.endDate).format("DD/MM/YYYY")}`;

    console.log(noOfAttendees);

    const booking = {
      bookingWindowId: bookingWindowId,
      bookingWindowName: bookingWindow?.name,
      bookingWindowLocation: bookingWindow?.location?.name,
        bookingWindowDuration: duration,
      //TODO: Fix this!!
      //userId: selectedCustomerGroup,
      userId: 107,
      attendeesCount: noOfAttendees,
      specialRequests: "",
      bookingItems: bookingItems,
    };

    try {
      const bookingCreated = await createBooking(booking);
      if(!bookingCreated)
      {
        setSaveLoading(false);
      }
      else{
        setSaveLoading(false);

        setSelectedLocation(null);
        setSelectedDay(null);
        setSelectedRoom(null);
        setBookingItems([]);
        setNoOfAttendees(0);
        setSelectedTimeSlot(null);
        setIdCounter(0);
        setEditing(false);

        history.push({
          pathname: "/room-bookings/booking-created-success",
          state: {
            from: "/room-bookings/create/:bookingWindowId",
            booking: booking,
            bookingId: bookingCreated?.bookingId,
            customerGroupSelected: customerGroup?.name,
            itoYearSelected: selectedITOYear,
        },
      });
      }
      
    } catch (err) {}
  };

  const removeBookingItem = (bookingItem) => {
    const filteredBookings = bookingItems.filter(
      (item) => item.index !== bookingItem.index
    );
    setBookingItems(filteredBookings);
  };

  const closeConfirmationModal = (confirmationChoice) => async () => {
    setConfirmationLoading(true);
    if (confirmationChoices.confirm === confirmationChoice) {
      history.push({
        pathname: "/room-bookings",
        state: {
          from: "/room-bookings/create/:bookingWindowId",
        },
      });
    }
    setDeleteConfirmationModalOpen(false);
    setConfirmationLoading(false);
  };

  const handleImageModalOpen = () => setIsImageModalOpen(true);
  const handleImageModalClose = () => setIsImageModalOpen(false);

  return (
    <>
      <Prompt
        when={editing && !deleteConfirmationModalOpen && !isImageModalOpen}
        message={() => `Are you sure you want to navigate away without saving?`}
      />
      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        closeModal={closeConfirmationModal}
        confirmLoading={confirmationLoading}
        heading="Cancel Booking"
        content={
          <div className="div-booking-item">
            <Unicons.UilExclamationCircle color={"#FD7125"} size={25} /> You are
            about to cancel this booking and lose all the changes. Please
            confirm that you would like to perform this action.
          </div>
        }
      />
      <Helmet>
        <title>De Beers Room Bookings Portal - Room Bookings</title>
      </Helmet>
      <Grid>
        <Grid.Row className="customer-selection-header desktop">
          <Grid.Column computer={4} mobile={16}>
            <CustomerGroupSelector hideCustomerSelector={true} />
          </Grid.Column>
          <Grid.Column
            computer={4}
            mobile={16}
            className="current-sight-column"
          >
          </Grid.Column>
          <Grid.Column computer={16} mobile={16}>
            <div className="border"></div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="mobile-view">
          <Grid.Column width={16}>
            <CustomerSelectionSlider
              //currentSight={currentSalesPeriod?.name}
              //loadingSalesPeriods={loading}
              //loadingCurrentSalesPeriod={loadingSalesPeriod}
              setSelectedYear={setSelectedITOYear}
              selectedYear={selectedITOYear}
              yearOptions={yearOptions}
              displayCustomer={false}
            />
          </Grid.Column>
        </Grid.Row>
        {bookingOpeningDates && bookingOpeningDates.length > 0 ? (
          <>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <StandardSmallHeading>
                  {bookingWindow?.name}
                </StandardSmallHeading>
                <StandardSubheading>Reserve your spot</StandardSubheading>
                <br></br>
                <Form>
                  <Form.Group width="equal" fluid>
                    {locationOptions.length > 1 ? (
                      <Form.Dropdown
                        value={selectedLocation}
                        onChange={(_, { value }) => onLocationChange(value)}
                        label="Location"
                        placeholder="Select location"
                        search
                        selection
                        options={locationOptions}
                        width={8}
                        error={isRoomPanelVisible}
                      />
                    ) : (
                      <div className="div-col">
                        <Form.Field>
                          <label className="label-color">Location</label>
                        </Form.Field>
                        <Form.Field className="location-field">
                          {locationOptions[0]?.name}
                        </Form.Field>
                      </div>
                    )}

                    <NumberFormat
                      name="noOfAttendees"
                      isAllowed={(values) => {
                        const { floatValue } = values;
                        return floatValue >= 0 || !floatValue;
                      }}
                      onValueChange={(value) => onAttendeesChange(value)}
                      decimalScale={0}
                      label="Total Attendees"
                      customInput={Form.Input}
                      error={isNumberOfAttendeesZero || isRoomCapacityReached}
                      width={8}
                      //TODO: FIX COLOUR
                    />

                  </Form.Group>
                </Form>
                <StandardSmallHeading>Booking Selection</StandardSmallHeading>
                <Segment className="segment-padding-left">
                  <p className="p-text-colour">
                    Select a day
                  </p>
                  <Grid>
                    <Grid.Row columns={5}>
                      {bookingOpeningDates?.map((date, index) => (
                        <Grid.Column>
                          <BookingDayCard
                            date={date}
                            handleDayClick={handleDayClick}
                            selectedDay={selectedDay}
                          ></BookingDayCard>
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  </Grid>
                  <br />
                  {isRoomPanelVisible && (
                    <div>
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                          <p className="p-text-colour">
                              Select a room
                            </p>
                          </Grid.Column>
                          <Grid.Column textAlign="right">
                            <Label
                            basic
                            onClick={handleImageModalOpen}
                            className="view-map"
                          >
                            View Room map
                          </Label>
                            <Modal
                              open={isImageModalOpen}
                              onClose={handleImageModalClose}
                              size="large"
                              closeIcon
                            >
                              <Header>Rooms Map</Header>
                              <Modal.Content>
                              <iframe
                                src={roomsMap}
                                width="100%"
                                height="630px"
                                title="Rooms Map"
                              />
                              </Modal.Content>
                            </Modal>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2}>
                          {locations[0] &&
                            locations[0].rooms?.length > 0 &&
                            locations[0].rooms?.map((room, index) => (
                              <Grid.Column className="column-room-padding">
                                <Segment
                                className={selectedRoom?.name === room.name 
                                  ? "selected-room isSelected" 
                                  : "selected-room isNotSelected"}
                                >
                                  <Radio
                                    label={
                                      room.name +
                                      ` (Max ${room.capacity} attendee(s))`
                                    }
                                    value={room.name}
                                    checked={selectedRoom?.name === room.name}
                                    onChange={() => onRoomChange(room.name)}
                                    className="room-name-width"
                                  />
                                </Segment>
                              </Grid.Column>
                            ))}
                        </Grid.Row>
                      </Grid>
                      <br />
                    </div>
                  )}

                  {isTimeSlotPanelVisible && (
                    <div>
                      <p className="p-text-colour">
                        Select a time slot</p>
                      <Grid>
                        <Grid.Row columns={3}>
                          {timeslots &&
                            timeslots?.length > 0 &&
                            timeslots?.map((time, index) => (
                              <Grid.Column>
                                <BookingTimeSlotCard
                                  handleTimeSlotCardClick={
                                    handleTimeSlotCardClick
                                  }
                                  selectedTimeSlot={selectedTimeSlot}
                                  timeslot={time}
                                ></BookingTimeSlotCard>
                              </Grid.Column>
                            ))}
                        </Grid.Row>
                      </Grid>
                      <br />
                    </div>
                  )}
                  {isDuplicateBookingItem && (
                    <Message error>
                      The same booking was already added to the summary.
                    </Message>
                  )}
                  {isRoomCapacityReached && (
                    <Message error>
                      The total number of attendees are more than the selected
                      room capacity. The room capacity for {selectedRoom?.name}{" "}
                      is {selectedRoom?.capacity}.
                    </Message>
                  )}
                  {isNumberOfAttendeesZero && (
                    <Message error>
                      The total attendees must be a number greater than zero.
                    </Message>
                  )}
                  {entireDayCardDisabled && (
                    <Message error>
                      The 'Entire Day' time slot cannot be selected because a
                      booking on the same day has been added to the summary for
                      either Morning or Afternoon.
                    </Message>
                  )}
                  <div className="right-text">
                    <Button
                      disabled={
                        !isBookingReady ||
                        isRoomCapacityReached ||
                        isNumberOfAttendeesZero ||
                        isDuplicateBookingItem ||
                        entireDayCardDisabled
                      }
                      primary
                      onClick={() => addBooking()}
                      className="btn-primary"
                    >
                      Add Booking
                    </Button>
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <StandardSmallHeading>Booking Summary</StandardSmallHeading>
                <br></br>
                {bookingItems && bookingItems.length > 0 ? (
                  <Segment>
                    <BookingItems
                      bookingItems={bookingItems}
                      removeBookingItem={removeBookingItem}
                      isEditButtonVisible={false}
                    ></BookingItems>
                  </Segment>
                ) : (
                  <Segment className="segment-padding">
                    <p>Your Bookings will come here</p>
                  </Segment>
                )}
                <Button
                  disabled={bookingItems?.length === 0}
                  primary
                  floated="right"
                  onClick={submitBooking}
                  loading={saveLoading}
                  className="btn-primary"
                >
                  Submit Booking
                </Button>
                <Button
                  secondary
                  floated="right"
                  onClick={() => setDeleteConfirmationModalOpen(true)}
                  className="btn-secondary"
                >
                  Cancel
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </>
        ) : (
          <Message
            icon="warning circle"
            header={`No Booking Openings Configured`}
            content={` There are no booking openings configured for the selected
              customer group.`}
          />
        )}
      </Grid>
    </>
  );
};

export default RoomBookingsCreateContainer;
