import { notifyError } from "./notifications";

const { createAxios } = require("./axiosWrapper");

// TODO: Replace Temp with actual controller!!
const bookingUrl = `${process.env.REACT_APP_GSS_ROOM_BOOKINGS_API_URL}/api/TempBookingWindow`;

const axios = () => createAxios(bookingUrl);

export const getBookingWindowDataAsync = async (
  selectedItoYear
) => {
  try {
    const requestUrl =
      `get-booking-windows-for-ito-year?itoYear=${selectedItoYear}`;

    const bookingWindows = await axios().get(requestUrl);

    return bookingWindows.data ? bookingWindows.data : [];
  } catch (err) {
    notifyError("Failed to get the booking windows.", err);
  }
};

export const getBookingWindowAsync = async (
  windowId
) => {
  try {
    const requestUrl =
      `get-booking-window-by-id?windowId=${windowId}`;

    const bookingWindow = await axios().get(requestUrl);

    return bookingWindow.data ? bookingWindow.data : [];
  } catch (err) {
    notifyError("Failed to get the booking window.", err);
  }
};


