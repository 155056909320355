import "./BookingTimeSlotCard.scss";
import React from "react";
import { Card } from "semantic-ui-react";

export const BookingTimeSlotCard = ({
  timeslot,
  handleTimeSlotCardClick,
  selectedTimeSlot,
}) => {
  return (
    <div>
      {/* TODO: MOVE TO INDIVIDUAL STYLESHEET */}
      <Card
        className="booking-time-slot-card"
        onClick={() => {
          handleTimeSlotCardClick(timeslot);
        }}
        disabled={true}
        style={{
          cursor: "pointer",
          backgroundColor:
            timeslot?.timeOfDay === selectedTimeSlot?.timeOfDay
              ? "#e8e8e8"
              : "white",
          boxShadow:
            timeslot?.timeOfDay === selectedTimeSlot?.timeOfDay
              ? "0 0 0 1px black"
              : "0 1px 3px 0 #d4d4d5,0 0 0 1px #d4d4d5",
          borderRadius: "0px",
        }}
      >
        <div>
          <p className="p-booking-item-week-day">{timeslot.timeOfDay}</p>
          <p className="p-booking-item-week-day">{timeslot.duration}</p>
        </div>
      </Card>
    </div>
  );
};

export default BookingTimeSlotCard;
