import "./CommonStyles.scss";
import "./RoomBookingsEditContainer.scss";
import React, { useContext, useEffect, useState } from "react";
// import { getCurrentSalesPeriod } from "../../lib/apiSalesPeriod";
import { CustomerSelectionSlider } from "../../common-mobile/CustomerSelectionSlider/CustomerSelectionSlider";
import { SelectedCustomerGroupContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { Grid, Segment, Button, Form, Icon } from "semantic-ui-react";
import { getCustomerGroup } from "../../lib/apiOrderManagement";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import * as Unicons from "@iconscout/react-unicons";
import {
  ConfirmationModal,
  confirmationChoices,
} from "../../common/ConfirmationModal/ConfirmationModal";
import Helmet from "react-helmet";
import {
  updateBooking,
  deleteBooking,
  getBookingAsync,
} from "../../lib/apiBookings";
import { useHistory, Prompt, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { Popup } from "../../common/Popup/Popup";
import { notifySuccess } from "../../lib/notifications";

/*
TODO: Clean up file:
-add more validations on the attendees and test more
- remove all in-line styling
- optimise some functions
*/

export const fetchCustomerGroup = async (setCustomerGroup, customerGroupId) => {
  if (customerGroupId) {
    const customerGroup = await getCustomerGroup(customerGroupId);
    setCustomerGroup(customerGroup);

    return customerGroup;
  }
};

const getBooking = async (
  setBooking,
  bookingId,
  setLoading,
  setBookingWindow
) => {
  try {
    setLoading(true);
    const bookingResponse = await getBookingAsync(bookingId);
    if (bookingResponse) {
      setBooking(bookingResponse);
      setBookingWindow(bookingResponse.bookingWindow);
    }
  } finally {
    setLoading(false);
  }
};

export const RoomBookingsManageContainer = ({
  match: {
    params: { bookingId },
  },
}) => {
  // const [currentSalesPeriod, setCurrentSalesPeriod] = useState(null);
  const [selectedITOYear, setSelectedITOYear] = useState(null);
  // const [loadingSalesPeriod, setLoadingSalesPeriod] = useState(false);
  const [yearOptions, setYearOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customerGroup, setCustomerGroup] = useState();
  const [specialRequests, setSpecialRequests] = useState("");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [bookingWindow, setBookingWindow] = useState();
  const [booking, setBooking] = useState();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);
  const { isReadOnly } = location.state || {};

  const [attendees, setAttendees] = useState([{ firstName: "", lastName: "" }]);
  const [errors, setErrors] = useState([{ firstName: false, lastName: false }]);

  // useEffect(() => {
  //   async function fetchData() {
  //     setLoadingSalesPeriod(true);
  //     const currentSP = await getCurrentSalesPeriod();
  //     setCurrentSalesPeriod(currentSP);
  //     setLoadingSalesPeriod(false);
  //   }
  //   fetchData();
  // }, []);

  useEffect(() => {
    fetchCustomerGroup(setCustomerGroup, selectedCustomerGroup);
  }, []);

  // useEffect(() => {
  //   if (currentSalesPeriod && yearOptions) {
  //     setSelectedITOYear(currentSalesPeriod?.name.substring(0, 4));
  //   }
  // }, [currentSalesPeriod, yearOptions]);

  useEffect(() => {
    if (yearOptions) {
      const currentYear = new Date().getFullYear();
      setSelectedITOYear(currentYear.toString());
    }
  }, [yearOptions]);

  useEffect(() => {
    getBooking(setBooking, bookingId, setLoading, setBookingWindow);
  }, [bookingId, setBooking, setBookingWindow]);

  useEffect(() => {
    if (booking) {
      if (booking.specialRequests) setSpecialRequests(booking.specialRequests);

      if (booking.bookingAttendees) {
        const newList = booking?.bookingAttendees.map((ba) => {
          return {
            firstName: ba.attendee?.firstName,
            lastName: ba.attendee?.lastName,
            attendeeId: ba.attendeeId,
          };
        });

        setAttendees(newList);
      }
    }
  }, [booking]);

  const isEmpty = attendees.every(
    (attendee) =>
      attendee.firstName.trim() === "" && attendee.lastName.trim() === ""
  );

  const handleAttendeeChange = (index, field, value) => {
    const updatedAttendees = [...attendees];
    updatedAttendees[index][field] = value;
    setAttendees(updatedAttendees);



    // Update errors for the current attendee
  

    // Update errors for the current attendee
    const updatedErrors = [...errors];
    updatedErrors[index] = {
      ...updatedErrors[index],
      firstName: !updatedAttendees[index].firstName,
      lastName: !updatedAttendees[index].lastName,
    };
    setErrors(updatedErrors);


    // Check if all fields are filled (no errors)
  
    // Check if all fields are filled (no errors)
    const hasNoErrors = updatedErrors.every(
      (error) => !error.firstName && !error.lastName
    );
    setIsSaveEnabled(hasNoErrors);
  };
  
  const addAttendee = () => {
    setAttendees([...attendees, { firstName: "", lastName: "" }]);
    setErrors([...errors, { firstName: false, lastName: false }]);
    setIsSaveEnabled(false);
  };
  
  const removeAttendee = (index) => {
    const updatedAttendees = attendees.filter((_, i) => i !== index);
    const updatedErrors = errors.filter((_, i) => i !== index);
    setAttendees(updatedAttendees);
    setErrors(updatedErrors);
  
    const hasNoErrors = updatedErrors.every(
      (error) => !error.firstName && !error.lastName
    );
    setIsSaveEnabled(hasNoErrors);
  };

  const saveSpecialRequests = (e, { value }) => {
    setSpecialRequests(value);
    setIsSaveEnabled(true);
  };

  const updateBookingDetails = async () => {
    setSaveLoading(true);

    try {
      const bookingAttendees = attendees.map((a) => {
        return {
          attendeeId: a.attendeeId,
          attendee: {
            attendeeId: a.attendeeId,
            firstName: a.firstName,
            lastName: a.lastName,
          },
        };
      });

      booking.specialRequests = specialRequests;
      booking.bookingAttendees = bookingAttendees;

      await updateBooking(booking);
      setSaveLoading(false);
      setIsSaveEnabled(false);
    } catch (err) {}
  };

  const cancelBooking = async () => {
    setSaveLoading(true);

    try {
      await deleteBooking(booking?.bookingId);
      setSaveLoading(false);

      history.push({
        pathname: "/room-bookings",
        state: {
          from: "/room-bookings/manage-booking/:bookingId",
          booking: booking,
          customerGroupSelected: customerGroup?.name,
          itoYearSelected: selectedITOYear,
        },
      });
    } catch (err) {}
  };

  const closeConfirmationModal = (confirmationChoice) => async () => {
    setConfirmationLoading(true);
    if (confirmationChoices.confirm === confirmationChoice) {
      try {
        cancelBooking(booking);
        notifySuccess("Booking cancelled successfully.");
      } catch {
        return;
      }
    }
    setDeleteConfirmationModalOpen(false);
    setConfirmationLoading(false);
  };

  //TODO: CHANGE ENDPOINT TO USE DISPLAY DTO
  const getDayOfTheWeek = (bookingItem) => {
    return bookingItem?.startTime
      ? moment(bookingItem.startTime).format("ddd")
      : "";
  };

  const getDay = (bookingItem) => {
    return bookingItem?.startTime
      ? String(new Date(bookingItem.startTime).getDate()).padStart(2, "0")
      : "";
  };

  const getMonth = (bookingItem) => {
    return bookingItem?.startTime
      ? moment(bookingItem.startTime).format("MMM")
      : "";
  };

  const getTimeSlot = (bookingItem) => {
    if (!bookingItem?.endTime || !bookingItem?.startTime) return "";

    const endTime = new Date(bookingItem.endTime);
    const startTime = new Date(bookingItem.startTime);

    // Extract hours from the time
    const endHours = endTime.getHours();
    const startHours = startTime.getHours();

    if (endHours === 11) {
      return "Morning (7am - 1pm)";
    } else if (startHours >= 11) {
      return "Afternoon (1:30pm - 7pm)";
    } else {
      return "Entire Day (7am - 7pm)";
    }
  };

  return (
    <>
      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        closeModal={closeConfirmationModal}
        confirmLoading={confirmationLoading}
        heading="Cancel Booking"
        content={
          <div className="div-booking-item">
            <Unicons.UilExclamationCircle color={"#FD7125"} size={25} /> You are
            about to cancel and delete this booking. Please confirm that you
            would like to perform this action.
          </div>
        }
      />
      <Helmet>
        <title>De Beers Room Bookings Portal - Room Bookings</title>
      </Helmet>
      <Grid>
        <Grid.Row className="customer-selection-header desktop">
          {/* <Grid.Column computer={4} mobile={16}>
            <CustomerGroupSelector hideCustomerSelector={true} />
          </Grid.Column> */}
          <Grid.Column
            computer={6}
            mobile={16}
            className="customer-group-heading"
            // floated="left"
            // textAlign="left"
          >
            {/* TODO: FIX THIS!! */}
            {/* Customer Group: {booking?.customerGroupName} */}
            Customer Group: A.Dalumi Diamonds Ltd.
          </Grid.Column>
          <Grid.Column computer={16} mobile={16}>
            <div className="border"></div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="mobile-view">
          <Grid.Column width={16}>
            <CustomerSelectionSlider
              //currentSight={currentSalesPeriod?.name}
              //loadingSalesPeriods={loading}
              //loadingCurrentSalesPeriod={loadingSalesPeriod}
              setSelectedYear={setSelectedITOYear}
              selectedYear={selectedITOYear}
              yearOptions={yearOptions}
              displayCustomer={false}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="row-back-room-bookings">
          {!isEmpty && (
            <a href="/room-bookings" className="back-navigation-button-text-color">
              <Icon name="left arrow" /> Back to bookings
            </a>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <h1 className="manage-booking-h1-heading">
              {booking?.bookingWindow.name}
            </h1>
          </Grid.Column>
          {!isReadOnly && (
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Button
                secondary
                floated="right"
                onClick={() => setDeleteConfirmationModalOpen(true)}
                className="btn-secondary"
              >
                Cancel Booking
              </Button>
              <Button
                primary
                floated="right"
                as={Link}
                to={`/room-bookings/edit/${booking?.bookingId}`}
                disabled={isEmpty}
                className="btn-primary"
              >
                Rebook
              </Button>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <h1 className="manage-booking-h1-heading-summary">
              Summary
            </h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="row-padding-top">
          <Grid.Column mobile={4} tablet={4} computer={2}>
            <p>Location</p>
            <p className="standard-booking-item-details">
              {booking?.bookingWindow?.location?.name}
            </p>
          </Grid.Column>
          <Grid.Column mobile={4} tablet={4} computer={2}>
            <p>Attendees</p>
            <p className="standard-booking-item-details">
              {booking?.attendeesCount}
            </p>
          </Grid.Column>
          <Grid.Column mobile={4} tablet={4} computer={2}>
            <p>Total Days</p>
            <p className="standard-booking-item-details">
              {booking?.bookingItems?.length}
            </p>
          </Grid.Column>
          <Grid.Column mobile={4} tablet={4} computer={2}>
            <p>Booked by</p>
            <p className="standard-booking-item-details">
              {/* TODO: FIX THIS!! */}
              {/* {booking?.createdBy} */}
              Jane Doe
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <StandardSmallHeading>Booking dates</StandardSmallHeading>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Grid>
              {/* TODO: MOVE THIS TO A COMMON PLACE TO BE SHARED WITH UPCOMING BOOKINGS */}
              <Grid.Row>
                {booking?.bookingItems?.length > 0 &&
                  booking?.bookingItems.map((bookingItem, index) => (
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                      <Segment className="segment-booking-card-details">
                        <Grid columns={2}>
                          <Grid.Column mobile={4} tablet={4} computer={2}>
                            <div>
                              <p className="p-booking-item-week-day">
                                {getDayOfTheWeek(bookingItem)}
                              </p>
                              <h2 className="h2-booking-item-day">
                                {getDay(bookingItem)}
                              </h2>
                              <p className="p-booking-item-week-day">
                                {getMonth(bookingItem)}
                              </p>
                            </div>
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div className="div-booking-item">
                              {" "}
                              <Unicons.UilLocationPoint className="icon-booking-item" />
                              <p>
                                {booking.bookingWindow?.location?.name},{" "}
                                {bookingItem.room?.name}
                              </p>
                            </div>
                            <br></br>
                            <div className="div-booking-item">
                              <Unicons.UilClockThree className="icon-booking-item" />
                              <p>{getTimeSlot(bookingItem)}</p>
                            </div>
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    </Grid.Column>
                  ))}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <StandardSmallHeading>Additional information</StandardSmallHeading>
          </Grid.Column>
          {!isReadOnly && (
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Button
                primary
                floated="right"
                onClick={updateBookingDetails}
                loading={saveLoading}
                disabled={isEmpty || !isSaveEnabled}
                className="btn-primary"
              >
                Save Changes
              </Button>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Segment>
              <StandardSmallHeading>Special Requests</StandardSmallHeading>
              <br />
              {!isReadOnly && (
                <>
                  <p>
                    Inform us about any additional information about your
                    booking so that we can provide you the best experience
                    during your time with us
                  </p>
                  <br />
                </>
              )}
              {!specialRequests && isReadOnly ? (
                <p>No Special Requests added.</p>
              ) : (
                <Form>
                  <Form.Field>
                    <Form.Group width="equal" fluid>
                      <Form.TextArea
                        className="input-textarea-border"
                        id="specialRequests"
                        name="specialRequests"
                        placeholder={
                          isReadOnly
                            ? "No Special Requests added."
                            : "Enter your request"
                        }
                        value={specialRequests}
                        onChange={saveSpecialRequests}
                        maxLength={500}
                        rows="4"
                        width={16}
                        readOnly={isReadOnly}
                      />
                    </Form.Group>
                  </Form.Field>
                </Form>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    <div className="div-booking-item">
                      <h1 className="manage-booking-h1-heading-attendee">
                        Attendee names
                        {!isReadOnly && (
                          <span className="manage-booking-asterisk">
                            *
                          </span>
                        )}
                      </h1>

                      <br></br>
                      {isEmpty && (
                        <Popup
                          content={"Please add attendee names to this booking."}
                          Icon={Unicons.UilExclamationCircle}
                          iconClassNames="error-info-icon"
                        />
                      )}
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={8}
                  ></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    {!isReadOnly && (
                      <p className="p-text-colour">Add the full names of all the attendees.</p>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="row-padding-bottom">
                  <Grid.Column mobile={16} tablet={16} computer={14}>
                    {!attendees && isReadOnly ? (
                      <p className="p-text-colour">No Attendee names added.</p>
                    ) : (
                      <Form>
                        {attendees.map((name, index) => (
                          <Form.Group key={index}>
                            <Form.Input
                              className="input-field-border"
                              placeholder={`First Name`}
                              value={name.firstName}
                              onChange={(e) =>
                                handleAttendeeChange(
                                  index,
                                  "firstName",
                                  e.target.value
                                )
                              }
                              width={7}
                              required
                              readOnly={isReadOnly}
                              error={
                                errors[index]?.firstName &&
                                { content: "First Name is required" }
                              }
                            />
                            <Form.Input
                            className="input-field-border"
                              placeholder={`Last Name`}
                              value={name.lastName}
                              onChange={(e) =>
                                handleAttendeeChange(
                                  index,
                                  "lastName",
                                  e.target.value
                                )
                              }
                              width={7}
                              required
                              readOnly={isReadOnly}
                              error={
                                errors[index]?.lastName &&
                                { content: "Last Name is required" }
                              }
                            />
                            <Button
                              icon
                              onClick={() => removeAttendee(index)}
                              type="button"
                              className="attendee-icon-button"
                            >
                              <Icon name="minus" />
                            </Button>

                          </Form.Group>
                        ))}
                      </Form>
                    )}
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={2}
                  ></Grid.Column>
                </Grid.Row>
                <Grid.Row className="row-padding-top">
                {!isReadOnly && (
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <Button 
                      fluid 
                      secondary 
                      onClick={addAttendee}
                      className="btn-secondary">
                      Add Name
                    </Button>
                  </Grid.Column>
                )}
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default RoomBookingsManageContainer;
