import { Checkbox, Divider, Form, Segment } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import "./Profile.scss";
// import {
//   getCurrentEmailPreference,
//   resubscribeEmail,
//   unsubscribeEmail
// } from "../../lib/apiEmail";

import Helmet from "react-helmet";
import { LoaderComponent } from "../../common/LoaderComponent";
import { StandardHeading } from "../../common/StandardHeading/StandardHeading";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import { getUserProfile } from "../../lib/authentication";

export const Profile = () => {
  const [userProfile, setUserProfile] = useState();
  const [emailPreference, setEmailPreference] = useState(false);
  const [loadingEmailPreference, setLoadingEmailPreference] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserProfile({ setUserInformation: setUserProfile, setLoading });
  }, []);

  // useEffect(() => {
  //   if (userProfile) {
  //     getCurrentEmailPreference({
  //       setLoading: setLoadingEmailPreference,
  //       setPreference: setEmailPreference,
  //     });
  //   }
  // }, [userProfile]);

  // const onEmailPreferenceChange = (_, { checked }) => {
  //   setEmailPreference(checked);
  //   if (!emailPreference) {
  //     resubscribeEmail({
  //       setLoading: setLoadingEmailPreference,
  //     });
  //   } else {
  //     unsubscribeEmail({
  //       setLoading: setLoadingEmailPreference,
  //     });
  //   }
  // };

  return (
    <Segment>
      <Helmet>
        <title>De Beers Room Bookings Portal - User Profile</title>
      </Helmet>
      <LoaderComponent loading={loading}>
        {userProfile && (
          <React.Fragment>
            <Form>
              <StandardHeading >
                {userProfile.firstName} {userProfile.lastName}&apos;s profile
              </StandardHeading>
              <Divider className="divider-colour"/>
              <Form.Group widths="equal">
                <Form.Field>
                  <label htmlFor="first-name" className="profile-text">First Name</label>
                  <input
                    readOnly
                    value={userProfile.firstName}
                    name="first-name"
                    className="black-outline-input"
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="last-name" className="profile-text">Surname</label>
                  <input
                    readOnly
                    value={userProfile.lastName}
                    name="last-name"
                    className="black-outline-input"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label htmlFor="email-address" className="profile-text">Email Address</label>
                  <input
                    readOnly
                    value={userProfile.emailAddress}
                    name="email-address"
                    className="black-outline-input"
                  />
                </Form.Field>
              </Form.Group>
              <StandardSubheading>Email Preferences</StandardSubheading>
              <Divider className="divider-colour"/>
              <p className="profile-text">
                Receive email communication from De Beers Room Bookings?
              </p>
              <Form.Group>
                <Form.Field className="toggle-field">
                  <Checkbox
                    disabled={loadingEmailPreference}
                    loading={loadingEmailPreference}
                    checked={emailPreference}
                    name="email-preference"
                    toggle
                    //TODO: FIX THIS!!
                    //onChange={onEmailPreferenceChange}
                  />
                  <label htmlFor="email-preference" className="profile-text">Yes</label>
                </Form.Field>
              </Form.Group>
            </Form>
          </React.Fragment>
        )}
      </LoaderComponent>
    </Segment>
  );
};
