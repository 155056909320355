import {
  CustomerGroupsContext,
  SelectedCustomerContext,
  SelectedCustomerGroupContext,
} from "../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { LoginCallback } from "@okta/okta-react";
import React, { Suspense, lazy, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import AppLayout from "../components/AppLayout/AppLayout";
import { Cookies } from "./login/Cookies";
import { GetOktaConfig } from "../lib/oktaConfig";
import { LoaderComponent } from "../common/LoaderComponent";
import { LoggedInWrapper } from "../common/LoggedInWrapper/LoggedInWrapper";
import Logout from "./login/Logout";
import { OktaAuth } from "@okta/okta-auth-js";
import { Profile } from "./profile/Profile";
import SpsLogin from "./login/SpsLogin";
import { TermsConditions } from "./login/TermsConditions";
import { PrivacyPolicy } from "./login/PrivacyPolicy";
import { tokenUserPermissions } from "../lib/tokenUserPermissions";
import { RoomBookingsContainer } from "./room-bookings/RoomBookingsContainer";
import { RoomBookingsCreateContainer } from "./room-bookings/RoomBookingsCreateContainer";
import { BookingCompleted } from "./room-bookings/BookingCompleted";
import { RoomBookingsManageContainer } from "./room-bookings/RoomBookingsManageContainer";
import { RoomBookingsEditContainer } from "./room-bookings/RoomBookingsEditContainer";
import { UpcomingAndPastBookings } from "./room-bookings/UpcomingAndPastBookings";

const SignIn = lazy(() => import("./login/SignIn"));
const ResetPasswordForm = lazy(() => import("./login/ResetPasswordForm"));
const UnlockAccountForm = lazy(() => import("./login/UnlockAccountForm"));
const HelpScreen = lazy(() => import("./login/HelpScreen"));

const OktaErrorHandler = lazy(() => import("./login/OktaErrorHandler"));

const NotAuthenticatedContainer = lazy(() =>
  import("./not-authenticated/NotAuthenticatedContainer")
);
const NotAuthorisedContainer = lazy(() =>
  import("./not-authorised/NotAuthorisedContainer")
);
const NotFoundContainer = lazy(() => import("./not-found/NotFoundContainer"));

const requireAuth = (Module, permission) => (props) => {
  return (
    <LoggedInWrapper key={props.location.pathname} permission={permission}>
      <Module {...props} />
    </LoggedInWrapper>
  );
};

export const Routes = () => {
  const [customerGroups, setCustomerGroups] = useState([]);
  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const customerGroupsValue = {
    customerGroups: customerGroups,
    setCustomerGroups: setCustomerGroups,
  };
  const selectedCustomerGroupValue = {
    selectedCustomerGroup: selectedCustomerGroup,
    setSelectedCustomerGroup: setSelectedCustomerGroup,
  };
  const selectedCustomerValue = {
    selectedCustomer: selectedCustomer,
    setSelectedCustomer: setSelectedCustomer,
  };

  const loginCallback = () => {
    return <LoginCallback errorComponent={OktaErrorHandler} />;
  };

  const history = useHistory();
  const onAuthRequired = () => {
    history.push("/login");
  };

  const oktaAuth = new OktaAuth({ onAuthRequired, ...GetOktaConfig() });
    return (
    //TODO: UNDO SECURITY CHANGES BELOW

    // <Security oktaAuth={oktaAuth}>
    <>
      {/* This is not a mistake Route path="/okta-login" must live outside of AppLayout otherwise bad things happen */}
      <Route path="/okta-login" render={loginCallback} />
      <Route exact path="/logout" component={Logout} />
        <SpsLogin>
          <AppLayout>
            <CustomerGroupsContext.Provider value={customerGroupsValue}>
              <SelectedCustomerGroupContext.Provider
                value={selectedCustomerGroupValue}
              >
                <SelectedCustomerContext.Provider
                  value={selectedCustomerValue}
                >
                  <Suspense fallback={<LoaderComponent loading={true} />}>
                    <Switch>
                      <Route exact path="/" render={() => <SignIn />} />
                      <Route path="/login" render={() => <SignIn />} />
                      <Route
                        path="/forgot-password"
                        render={() => <SignIn />}
                      />
                      <Route
                        path="/reset-password"
                        render={() => <ResetPasswordForm />}
                      />
                      <Route
                        path="/unlock-account"
                        render={() => <UnlockAccountForm />}
                      />
                      <Route exact path="/help" component={HelpScreen} />
                      <Route
                        exact
                        path="/termsconditions"
                        component={TermsConditions}
                      />
                      <Route exact path="/privacypolicy" component={PrivacyPolicy} />
                      <Route exact path="/cookies" component={Cookies} />

                      <Route
                        exact
                        path="/not-authenticated"
                        component={NotAuthenticatedContainer}
                      />
                      <Route
                        exact
                        path="/not-authorised"
                        component={NotAuthorisedContainer}
                      />
                      <Route
                        path="/profile"
                        render={requireAuth(
                          Profile,
                          tokenUserPermissions.CUSTOMER_PORTAL
                        )}
                      />
                      <Route
                        exact
                        path="/room-bookings"
                        render={requireAuth(
                          RoomBookingsContainer,
                          tokenUserPermissions.CUSTOMER_PORTAL
                        )}
                      />
                      <Route
                        path="/room-bookings/create/:bookingWindowId"
                        render={requireAuth(
                          RoomBookingsCreateContainer,
                          tokenUserPermissions.CUSTOMER_PORTAL
                        )}
                      />
                      <Route
                        path="/room-bookings/booking-created-success"
                        render={requireAuth(
                          (props) => BookingCompleted(props),
                          tokenUserPermissions.CUSTOMER_PORTAL
                        )}
                      />
                      <Route
                        path="/room-bookings/manage-booking/:bookingId"
                        render={requireAuth(
                          RoomBookingsManageContainer,
                          tokenUserPermissions.CUSTOMER_PORTAL
                        )}
                      />
                        <Route
                        path="/room-bookings/edit/:bookingId"
                        render={requireAuth(
                          RoomBookingsEditContainer,
                          tokenUserPermissions.CUSTOMER_PORTAL
                        )}
                      />
                      <Route
                        path="/room-bookings/past"
                        render={requireAuth(
                          UpcomingAndPastBookings,
                          tokenUserPermissions.CUSTOMER_PORTAL
                        )}
                      />
                      <Route component={NotFoundContainer} />
                    </Switch>
                  </Suspense>
                </SelectedCustomerContext.Provider>
              </SelectedCustomerGroupContext.Provider>
            </CustomerGroupsContext.Provider>
          </AppLayout>
        </SpsLogin>
    {/*TODO: UNDO SECURITY CHANGE BELOW*/}
    {/* </Security> */}
    </>
  );
};
