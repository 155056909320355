import "./NavBarIcons.scss";
import { Icon } from "semantic-ui-react";
import React from "react";
import { useHistory } from "react-router-dom";


export const NavBarIconsContainer = () => {
    const history = useHistory();

    const handleHomeClick = () => {
        history.push({
          pathname: `/room-bookings`,
          state: {
            //from: "/room-bookings/booking-created-success",
          },
        });
       }

    return (
        <>
        <Icon
          name="home "
          size="large"
          className="home"
          onClick={handleHomeClick}
        >
        </Icon>
        {/* <i 
        className="grid layout icon custom-3x3-grid"
        >
        </i> */}
        </>
        
    );
};