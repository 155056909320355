import React from "react";
import "../../styles/colors.scss";

export const StandardHeading = ({children, blueUnderline = false}) => {

    return (
        <div className={(blueUnderline ? "blue-underline " : "") + "standard-heading"}>
            {children}
        </div>
    )
};
