import "./FutureBookings.scss";
import React, { useState, useEffect } from "react";
import { Grid, Divider, Card, Button, Segment } from "semantic-ui-react";
import { LoaderComponent } from "../../common/LoaderComponent";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import { getBookingWindowDataAsync } from "../../lib/apiBookingWindows";
import moment from "moment";
import { Link } from "react-router-dom";

const getBookingWindowData = async (
  selectedItoYear,
  setLoadingBookingData,
  setBookingData
) => {
  setLoadingBookingData(true);

  const data = await getBookingWindowDataAsync(selectedItoYear);

  await setBookingData(data);

  setLoadingBookingData(false);
};

export const FutureBookings = ({ selectedItoYear }) => {
  const [loadingBookingWindowData, setLoadingBookingWindowData] =
    useState(false);
  const [bookingWindowData, setBookingWindowData] = useState([]);

  useEffect(() => {
    if (selectedItoYear) {
      getBookingWindowData(
        selectedItoYear,
        setLoadingBookingWindowData,
        setBookingWindowData
      );
    }
  }, [selectedItoYear]);

  const currentDate = new Date();

  return (
    <LoaderComponent loading={loadingBookingWindowData}>
      <StandardSubheading>Future Sight Weeks</StandardSubheading>

      <Divider className="divider-colour"/>
      {bookingWindowData?.length > 0 ? (
        bookingWindowData?.map((window, index) => (
          <Card className="notification-card">
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <StandardSmallHeading>{window.name}</StandardSmallHeading>
                  <p className="p-sight-week-date">
                    {moment(window.startDate).format("DD/MM/YYYY")} -{" "}
                    {moment(window.endDate).format("DD/MM/YYYY")}
                  </p>
                </Grid.Column>
                <Grid.Column width={8} className="button-sight-week-card">
                  {currentDate > moment(window.startDate) &&
                  currentDate < moment(window.endDate) ? (
                    <Button
                      primary
                      floated="right"
                      as={Link}
                      to={`/room-bookings/create/${window.bookingWindowId}`}
                      className="btn-primary"
                    >
                      Book Now
                    </Button>
                  ) : (
                    <p>Not Yet Open</p>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
        ))
      ) : (
        <Segment>
          <p>There are no sight weeks configured for the year.</p>
        </Segment>
      )}
    </LoaderComponent>
  );
};

export default FutureBookings;
