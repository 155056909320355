import { withFormik } from "formik";
import React from "react";
import { RoomBookingsEditForm } from "./RoomBookingsEditForm";
import { updateBooking } from "../../lib/apiBookings";

const handleSubmit = async (values, { props }) => {
  props.setLoading(true);

  try {
    const bookingToUpdate = {};

    await updateBooking(bookingToUpdate);

    props.setRefresh((currentValue) => !currentValue);
    props.setIsOpen(false);
  } finally {
    props.setLoading(true);
  }
};

const EditBookings = withFormik({
  mapPropsToValues: (props) => ({
    bookingId: props.bookingId,
  }),
  displayName: "Edit Bookings",
  handleSubmit,
})(RoomBookingsEditForm);

export const RoomBookingsEditContainer = ({
  match: {
    params: { bookingId },
  },
}) => {
  return <EditBookings bookingId={bookingId} />;
};
