import "./BookingItems.scss";
import React, { useState }  from "react";
import { Grid, Card, Button } from "semantic-ui-react";
import { IconButton } from "../../common/IconButton/IconButton";
import { UilTrashAlt, UilCalender, UilEditAlt } from "@iconscout/react-unicons";

export const BookingItems = ({ bookingItems, removeBookingItem, openBookingItem, selectedItem, isEditButtonVisible }) => {
  return (
    <>
      {bookingItems?.length > 0 &&
        bookingItems?.map((bookingItem, index) => (
          <Card 
            className={`notification-card ${bookingItem?.index === selectedItem?.index 
              ? "booking-item-card isSelected" 
              : "booking-item-card isNotSelected"}`}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={6}>
                  <div className="booking-item-date">
                    <UilCalender className="icon-booking-item" />
                    <h1 className="booking-item-h1-heading" >
                      {bookingItem.dateString}
                    </h1>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <p className="booking-item-spacing">Room</p>
                  <h2 className="booking-item-h2-heading">
                    {bookingItem.roomName}
                  </h2>
                </Grid.Column>
                <Grid.Column width={8}>
                  <p className="booking-item-spacing">Time slot</p>
                  <h2 className="booking-item-h2-heading">
                    {bookingItem.timeSlotString}
                  </h2>
                </Grid.Column>
                <Grid.Column
                  width={4}
                  className="right-text"
                >
                  {isEditButtonVisible && (
                       <Button 
                       onClick={() => openBookingItem(bookingItem)}
                       icon={UilEditAlt}
                       className={`ui icon button ${bookingItem?.index === selectedItem?.index 
                        ? "booking-item-edit-icon isSelected" 
                        : "booking-item-edit-icon isNotSelected"}`}
                     ><UilEditAlt  className="icon-booking-item"/></Button>
                  )}
                  <Button 
                    onClick={() => removeBookingItem(bookingItem)}
                    icon={UilTrashAlt}
                    className={`ui icon button ${bookingItem?.index === selectedItem?.index 
                      ? "booking-item-edit-icon isSelected" 
                      : "booking-item-edit-icon isNotSelected"}`}
                  ><UilTrashAlt  className="icon-booking-item"/></Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
        ))}
    </>
  );
};

export default BookingItems;
