import "./MobileMenu.scss";

import { Button, Icon, Image, Menu, Sidebar } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { MobileMenuContent } from "./MobileMenuContent";
import logo from "../../media/debeers-logo-small-white.png";
import { withRouter } from "react-router-dom";

export const MobileMenu = withRouter(({
  location,
  showSidebar,
  setShowSidebar,
  history,
}) => {
  const [currentPath, setCurrentPath] = useState("");
  const extractCurrentLocation = (setCurrentPath, pathname) => {
    const path = pathname.split("/")[1] || "room-bookings";
    setCurrentPath(path);
  };

  useEffect(() => {
    extractCurrentLocation(setCurrentPath, location.pathname);
  }, [location]);
  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      visible={showSidebar}
      className="mobile-sidebar-container"
    >
      <div>
        <div className="menu-header">
          <Image src={logo} size="small" className="company-logo" />
          <Icon
            className="white"
            name="close"
            size="large"
            color="black"
            inverted
            onClick={() => {setShowSidebar(!showSidebar)}}
          />
        </div>
        <div className="menu-content">
          <MobileMenuContent currentPath={currentPath} setShowSidebar={setShowSidebar}/>
        </div>
        <div className="menu-footer">
          <div className="log-out">
            <Button onClick={() => history.push("/logout")}>Logout</Button>
          </div>
        </div>
      </div>
    </Sidebar>
  );
});
