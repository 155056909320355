import { notifyError } from "./notifications";
import { createAxios } from "./axiosWrapper";
import { sortBy } from "lodash";

const userApiUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api`;

const axios = () => createAxios(userApiUrl);

//TODO: UNDO CHANGES BELOW
export const getUserCustomers = async () => {
  try {
    const userCustomerData = [
      {
        "customerGroupId": 107,
        "customerGroupName": "A.Dalumi Diamonds Ltd.",
        "customers": [
          {
            "customerId": 275,
            "customerName": "A.Dalumi Botswana",
            "location": "De Beers Botswana"
          },
          {
            "customerId": 303,
            "customerName": "A.Dalumi Diamonds Ltd.",
            "location": "De Beers International"
          }
        ]
      }
    ]
    return userCustomerData;
    // const requestUrl = "get-user-customers";
    // const customerGroupRequest = await axios().get(`/user/${requestUrl}`);
    // const response = customerGroupRequest.data || [];
    // return sortBy(response, ["customerGroupName"]);
  } catch (error) {
    notifyError("Failed to get the customers.", error);
  }
};
