import "./login.scss";

import { Grid, Icon, Segment } from "semantic-ui-react";

import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import React from "react";

export const Cookies = () => {
  return (
    <>
      <Helmet>
        <title>De Beers Room Bookings Portal - Cookies</title>
      </Helmet>
      <Grid
        textAlign="center"
        verticalAlign="middle"
        className="terms-conditions"
      >
        <Grid.Column className="terms-holder">
          <Segment className="terms-container">
            <Grid textAlign="left">
              <Grid.Row>
                <Grid.Column>
                  <Icon
                    name="arrow left"
                    className="help-back-navigation-icon"
                  />
                  <span className="back-navigation-button-text">
                    <Link to="/room-bookings" className="back-navigation-button-text-color">
                      Back to Room Bookings
                    </Link>
                  </span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                    <div className="footer-header">COOKIE POLICY</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <p className="p-text-colour">
                    <b>Please read this cookie policy alongside our Privacy Policy.</b>
                  </p>

                  <p className="p-text-colour">
                    A cookie is a small piece of data (text file) that a 
                    website – when visited by a user – asks your browser to 
                    store on your device in order to remember information about 
                    you, such as your language preference or login information. 
                    Those cookies are set by us and called first-party cookies.
                  </p>

                  <p className="p-text-colour">
                    You have the ability to accept or decline cookies. Most Internet browsers 
                    automatically accept cookies, but you can usually modify your browser 
                    settings to decline cookies or to notify you when a cookie is being 
                    placed on your computer. If you continue to use our platforms without 
                    changing your browser settings, we will use cookies as set out in this 
                    cookie policy. If you choose to use your browser settings to decline, 
                    disable, reject or block cookies, you may not be able to fully experience 
                    the features of our platform or other websites that you visit.
                  </p>
                  <p className="extra-space">
                    To learn more about cookies and how to disable them we recommend 
                    you visit the Information Commissioner’s website
                    <a href="https://ico.org.uk/for-the-public/online/cookies/"> here</a>.
                  </p>

                  <p className="p-text-colour">
                    The cookies we use fall into the following categories:
                  </p>

                  <p className="p-text-colour">
                    <b>
                      Necessary cookies
                    </b>
                  </p>

                  <p className="p-text-colour">
                    These cookies are essential for our portal and software to function. 
                    If you adjust your browser settings to decline these cookies our 
                    platforms and software will not function properly, and in some cases at all.
                  </p>

                  <p className="p-text-colour">
                    <b>
                      Performance cookies
                    </b>
                  </p>
                  <p className="p-text-colour">
                    These cookies allow us to count visits and traffic sources 
                    so we can measure and improve the performance of our site. 
                    They help us to know which pages are the most and least popular 
                    and see how visitors move around the site. All information 
                    these cookies collect is aggregated and therefore anonymous. 
                    If you do not allow these cookies we will not know when you 
                    have visited our site, and will not be able to monitor its performance.
                  </p>
                  
                </Grid.Column>
              </Grid.Row>
              {/* <Grid.Row>
                <Grid.Column>
                  <div id="ot-sdk-cookie-policy"></div>
                  <button id="ot-sdk-btn" class="ot-sdk-show-settings">
                    Cookie Settings
                  </button>
                </Grid.Column>
              </Grid.Row> */}
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};
