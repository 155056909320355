import "./MenuContent.scss";

import { withRouter } from "react-router-dom";
import React, { Fragment } from "react";

// import classNames from "classnames";
// import { tokenUserPermissions } from "../../lib/tokenUserPermissions";
// import { validateRole } from "../../lib/validateRole";

export const MenuContent = withRouter(({ location, history }) => {
  // const [currentPath, setCurrentPath] = useState("");

  // useEffect(() => {
  //   extractCurrentLocation(setCurrentPath, location.pathname);
  // }, [location.pathname]);

  // const activeLeftNavCheck = (path) => ({
  //   "active-left-nav": path === currentPath,
  // });

  // const gssRoomBookingsFeatureFlag =
  //   process.env.REACT_APP_FEATURE_FLAG_GSS_ROOM_BOOKINGS === "True";
  // const allowInsight = process.env.REACT_APP_ALLOW_INSIGHTS === "True";

  // const gssRoomBookingsNewExpiryDate =
  //   process.env.REACT_APP_GSS_ROOM_BOOKINGS_NEW_EXPIRY_DATE;

  // const expiryDate =
  //   gssRoomBookingsNewExpiryDate ?? new Date(gssRoomBookingsFeatureFlag);

  // const currentDate = new Date();

  //TODO: Need to consider date formats
  // const hasNewLabelExpired =
  //   gssRoomBookingsFeatureFlag && expiryDate && currentDate > expiryDate;

  return (
    <Fragment>
      {/* {validateRole(tokenUserPermissions.CUSTOMER_PORTAL) && (
        <Menu.Item
          as={Link}
          to={"/room-bookings"}
          className={classNames(
            "menu-item",
            "room-bookings",
            activeLeftNavCheck("room-bookings")
          )}
        >
          HOME
        </Menu.Item>
      )} */}
    </Fragment>
  );
});
